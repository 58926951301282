define("client-cma/src/order/tosaparinvoice/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    requestPaymentType: '',
    counsellingErrorDatails: [],
    toast: Ember.inject.service(),
    actions: {
      toSapArInvoice() {
        const excelData = document.querySelector('#exceldata');

        if (excelData == null || excelData.files[0] == null) {
          this.toast.error("No file selected");
          return;
        }

        this.send('importCounsellingToArInvoice', excelData.files[0]);
      },

      inputOnChange(e) {// this.send('checkRequestPaymentType', e.target.files[0])
      }

    }
  });

  _exports.default = _default;
});