define("client-cma/src/asteroom/route", ["exports", "client-cma/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    auth: Ember.inject.service(),
    session: Ember.inject.service(),
    toast: Ember.inject.service(),

    async model() {
      try {
        const token = this.session.data.authenticated.token;
        const response = await this.auth.isAuthenticated(token);

        if (response.ok) {
          if (this.session.data.authenticated.canUseAsteroom === false) {
            alert("你沒有權限，請洽管理員");
            this.transitionTo('/');
          }

          const account = response.data.email;
          console.log(this.session.data.authenticated);
          const {
            canUseAsteroom,
            canImportAsteroomDynamicCustomer,
            canUsePpInc
          } = this.session.data.authenticated;
          let options = [{
            content: 'Processing Orders',
            route: '/asteroom/order/process'
          }, {
            content: 'Shipped Orders',
            route: '/asteroom/order/complete'
          }, {
            content: 'All Orders',
            route: '/asteroom/order/list'
          }, {
            content: 'Return Orders',
            route: '/asteroom/order/return'
          }, {
            content: 'Calibrate Address',
            route: '/asteroom/order/address'
          }, {
            content: 'RP TO SAP',
            route: '/asteroom/order/rptosap'
          }, {
            content: 'Amazon FBA orders to sap',
            route: '/asteroom/order/amazonfbatosap'
          }, {
            content: 'Free hardware',
            route: '/asteroom/order/freehw'
          }];

          if (canUseAsteroom && !canUsePpInc && canImportAsteroomDynamicCustomer) {
            options = options.filter(o => o.route === '/asteroom/order/rptosap');
          }

          return {
            options: options,
            account: account
          };
        } else {
          throw response;
        }
      } catch (error) {
        this.toast.error('AUTHENTICATE_FAIL');
        await this.session.invalidate();
        this.transitionTo('login');
      }
    },

    setupController(controller, model) {
      controller.set('filter', '');
      controller.set('model', model);

      if (_environment.default.environment !== 'production') {
        document.documentElement.dataset.isTest = 'true';
      }
    },

    actions: {
      homePage() {
        this.transitionTo('/');
      }

    }
  });

  _exports.default = _default;
});