define("client-cma/src/order/complete/route", ["exports", "client-cma/utils/Data"], function (_exports, _Data) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    order: Ember.inject.service(),
    toast: Ember.inject.service(),

    async orderList4Shipped(token, queryParams) {
      let dataSource = [];

      try {
        const response = await this.order.orderList4Shipped(token, queryParams);

        if (response.ok) {
          dataSource = response.data;
        } else {
          throw response;
        }

        return dataSource;
      } catch (error) {
        if (error.code === 401) {
          await this.session.invalidate();
          this.transitionTo('/');
        } else if (error.code === 403) {
          this.transitionTo('/');
        } else {
          this.toast.error(error.message);
        }
      }
    },

    download(data) {
      let elm = document.createElement('a');
      elm.href = URL.createObjectURL(data.blob);
      elm.setAttribute('download', data.fileName);
      elm.click();
    },

    async model() {
      const token = this.session.data.authenticated.token;
      const {
        buttons,
        columns,
        chineseColumns,
        chinesebuttons
      } = this;
      const isChinesePage = window.isChinesePage();

      var startDateAndEndDate = _Data.default.getStartDateAndEndDate(isChinesePage);

      let queryParams = {
        startDate: startDateAndEndDate.startDate,
        endDate: startDateAndEndDate.endDate
      }; // if(isChinesePage){
      //     queryParams = {
      //         startDate: Data.startOfTaipeiTimeZone('month'),
      //         endDate: Data.endOfTaipeiTimeZone('month')
      //     }
      // }

      const dataSource = await this.orderList4Shipped(token, queryParams);
      let selectedColumns = columns;
      let selectedButtons = buttons;

      if (isChinesePage) {
        selectedColumns = chineseColumns;
        selectedButtons = chinesebuttons;
      }

      return {
        options: {
          data: dataSource,
          buttons: selectedButtons,
          columns: selectedColumns,
          select: {
            style: 'multi',
            selector: 'td:not(:first-child :last-child)'
          }
        },
        datetimeOptions: {
          format: 'YYYY-MM',
          viewMode: 'months',
          timezone: isChinesePage ? 'Asia/Taipei' : 'America/Los_Angeles'
        },
        storeTitle: window.getStoreTitle()
      };
    },

    setupController(controller, model) {
      controller.set('filter', '');
      controller.set('model', model);
    },

    actions: {
      async setReturnOrders(dt) {
        const token = this.session.data.authenticated.token;
        let selectedSNS = dt.rows({
          selected: true
        }).data().toArray().map(data => data.shopifySN);

        try {
          this.send('startSpinner');
          const response = await this.order.setReturnOrders(token, selectedSNS);

          if (response.ok) {
            this.controller.send('filterData', dt);
            this.toast.success('Succcessful');
          } else {
            throw response;
          }
        } catch (error) {
          if (error.code === 401) {
            await this.session.invalidate();
            this.transitionTo('/');
          } else if (error.code === 403) {
            alert("沒有操作權限");
          } else {
            this.toast.error(error.message);
          }
        } finally {
          this.send('stopSpinner');
        }
      },

      async downloadPackingList(dt) {
        const token = this.session.data.authenticated.token;
        let selectedSNS = dt.rows('.selected').data().toArray().map(data => data.shopifySN);

        try {
          this.send('startSpinner');
          const response = await this.order.downloadPickingList(token, selectedSNS);

          if (response.ok) {
            this.download(response.data);

            if (dt) {
              this.controller.send('filterData', dt);
            } else {
              this.controller.send('selectTheStatus', null);
            }

            this.toast.success('Completed');
          } else {
            throw response;
          }
        } catch (error) {
          if (error.code === 401) {
            await this.session.invalidate();
            this.transitionTo('/');
          } else if (error.code === 403) {
            this.transitionTo('/');
          } else {
            this.toast.error(error.message);
          }
        } finally {
          this.send('stopSpinner');
        }
      },

      async LetterOfThanks(data, dt) {
        const token = this.session.data.authenticated.token;

        try {
          this.send('startSpinner');
          const response = await this.order.sendEmail(token, data);

          if (response.ok) {
            this.controller.send('filterData', dt);
            this.toast.success('Send the Letter of Thanks Succcessfully');
          } else {
            throw response;
          }
        } catch (error) {
          if (error.code === 401) {
            await this.session.invalidate();
            this.transitionTo('/');
          } else if (error.code === 403) {
            alert("請至Shopify後台操作");
          } else {
            this.toast.error(error.message);
          }
        } finally {
          this.send('stopSpinner');
        }
      },

      async orderList4Shipped(data, dt) {
        const token = this.session.data.authenticated.token;
        const isChinesePage = window.isChinesePage(); // const time = data.date
        // const localtz = moment.tz.guess()
        // const date = time.clone().tz(localtz)
        // console.log(date.utc().format());
        // console.log("yes");

        var startDateAndEndDate = _Data.default.getStartDateAndEndDate(isChinesePage, data.date);

        let queryParams = {
          startDate: startDateAndEndDate.startDate,
          endDate: startDateAndEndDate.endDate
        }; // if(isChinesePage){
        //     console.log("yes");
        //     console.log(data.mode);
        //     console.log(data.date);
        //     queryParams = {
        //         startDate: Data.startOfTaipeiTimeZone(data.mode, data.date),
        //         endDate: Data.endOfTaipeiTimeZone(data.mode, data.date)
        //     }
        // }

        if (!_Data.default.isEmptyString(data.filter)) {
          queryParams = {};
          queryParams.keyword = data.filter;
        }

        const dataSource = await this.orderList4Shipped(token, queryParams);
        console.log(dataSource);
        dt.clear().rows.add(dataSource).draw();
        dt.rows().deselect();
        dt.columns.adjust();
        $('table thead .details-control').css('width', '15px');
      },

      test() {
        return "hk";
      }

    },
    buttons: [{
      key: 'downloadPackingList',
      text: 'Download Packing List',
      className: 'btn-primary'
    }, {
      key: 'setReturnOrders',
      text: 'Cancel & Return Orders',
      className: 'btn-primary'
    } // {
    //     key: 'LetterOfThanks',
    //     text: 'Send the Thank you letter',
    //     className: 'btn-primary',
    // },
    ],
    chinesebuttons: [{
      key: 'downloadPackingList',
      text: 'Download Packing List',
      className: 'btn-primary'
    }, {
      key: 'setReturnOrders',
      text: '退貨',
      className: 'btn-primary'
    } // {
    //     key: 'LetterOfThanks',
    //     text: 'Send the Thank you letter',
    //     className: 'btn-primary',
    // },
    ],
    columns: [{
      data: null,
      orderable: false,
      className: 'details-control',
      render: () => '<i class="fa fa-plus" aria-hidden="true"></i>'
    }, {
      data: null,
      orderable: false,
      checkboxes: {
        selectRow: true,
        selectAll: true,
        selectAllRender: '<input type="checkbox" class="dt-checkboxes">'
      }
    }, {
      data: 'shopifySN',
      title: 'Shopify SN',
      visible: false
    }, {
      data: 'idDisplay',
      title: 'ID NO'
    }, {
      data: 'dueDate',
      title: 'Due Date',
      render: (data, type, row, meta) => {
        return moment(data).tz('America/Los_Angeles').format('YYYY-MM-DD HH:mm:ss');
      }
    }, {
      data: 'email',
      title: 'Email'
    }, {
      data: 'shippedAddress',
      title: 'Shipping Address'
    }, {
      data: 'sapId',
      title: 'Sap Id',
      defaultContent: '-'
    }, {
      data: 'trackingCompany',
      title: 'Tracking Company',
      defaultContent: 'Fedex',
      className: 'text-center'
    }, {
      data: 'isShipped',
      title: 'Ship<br/>Status',
      defaultContent: '-',
      className: 'text-center',
      render: data => {
        if (data) {
          return '<i class="fa fa-check-circle fa-lg" aria-hidden="true"></i>';
        } else {
          return '<i class="fa fa-times-circle fa-lg" aria-hidden="true"></i>';
        }
      }
    }, // { 
    //     data: 'isDownloaded', 
    //     title: 'Download<br/>Status', 
    //     defaultContent: '-', 
    //     className: 'text-center', 
    //     render: (data) => {
    //         if(data) {
    //             return `<span class="badge badge-success">${data}</span>`;
    //         } else {
    //             return `<span class="badge badge-danger">${data}</span>`;
    //         }
    //     },
    // },
    {
      data: 'shipMailStatus',
      title: 'Mail<br/>Status',
      defaultContent: '-',
      className: 'send-email text-center',
      render: data => {
        return `<span class="badge">${data}</span><button id="send-email" class="btn btn-sm btn-link"><i class="fa fa-envelope" aria-hidden="true"></i> send mail</button>`;
      }
    } // { 
    //     data: 'cancelStatus', 
    //     title: 'Cancel<br/>Status', 
    //     defaultContent: '-', 
    //     className: 'text-center', 
    //     render: (data) => {
    //         return `<span class="badge">${data ? data : '-'}</span>`;
    //     } 
    // },
    // { data: 'note', title: 'Note', defaultContent: '-',className: 'text-center' },
    ],
    chineseColumns: [{
      data: null,
      orderable: false,
      className: 'details-control',
      render: () => '<i class="fa fa-plus" aria-hidden="true"></i>'
    }, {
      data: null,
      orderable: false,
      checkboxes: {
        selectRow: true,
        selectAll: true,
        selectAllRender: '<input type="checkbox" class="dt-checkboxes">'
      }
    }, {
      data: 'shopifySN',
      title: 'Shopify SN',
      visible: false
    }, {
      data: 'idDisplay',
      title: '訂單號碼'
    }, {
      data: 'dueDate',
      title: 'Due Date',
      render: (data, type, row, meta) => {
        return moment(data).tz('Asia/Taipei').format('YYYY-MM-DD HH:mm:ss');
      }
    }, {
      data: 'email',
      title: '信箱'
    }, {
      data: 'shippedAddress',
      title: '運送地址'
    }, {
      data: 'sapId',
      title: 'Sap Id',
      defaultContent: '-'
    }, // { 
    //     data: 'trackingCompany', 
    //     title: '物流公司', 
    //     defaultContent: 'Fedex', 
    //     className: 'text-center' 
    // },
    {
      data: 'trackingNumber',
      title: 'trackingNumber',
      orderable: false,
      render: data => {
        if (data) return data;else return '';
      }
    }, {
      data: 'isShipped',
      title: '是否出貨',
      defaultContent: '-',
      className: 'text-center',
      render: data => {
        if (data) {
          return '<i class="fa fa-check-circle fa-lg" aria-hidden="true"></i>';
        } else {
          return '<i class="fa fa-times-circle fa-lg" aria-hidden="true"></i>';
        }
      }
    }, // { 
    //     data: 'isDownloaded', 
    //     title: 'Download<br/>Status', 
    //     defaultContent: '-', 
    //     className: 'text-center', 
    //     render: (data) => {
    //         if(data) {
    //             return `<span class="badge badge-success">${data}</span>`;
    //         } else {
    //             return `<span class="badge badge-danger">${data}</span>`;
    //         }
    //     },
    // },
    {
      data: 'shipMailStatus',
      title: '是否寄信成功',
      defaultContent: '-',
      className: 'send-email text-center',
      render: data => {
        return `<span class="badge">${data}</span><button id="send-email" class="btn btn-sm btn-link"><i class="fa fa-envelope" aria-hidden="true"></i> send mail</button>`;
      }
    } // { 
    //     data: 'cancelStatus', 
    //     title: 'Cancel<br/>Status', 
    //     defaultContent: '-', 
    //     className: 'text-center', 
    //     render: (data) => {
    //         return `<span class="badge">${data ? data : '-'}</span>`;
    //     } 
    // },
    // { data: 'note', title: 'Note', defaultContent: '-',className: 'text-center' },
    ]
  });

  _exports.default = _default;
});