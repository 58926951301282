define("client-cma/routes/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    toast: Ember.inject.service(),

    redirect(model, transition) {
      window.getShopifyStore = function () {
        var store = location.pathname.split("/")[1];

        if (store === 'asteroom') {
          return "Asteroom";
        } else if (store === 'pptw') {
          return "PpTw";
        } else if (store === 'pphk') {
          return "PpHk";
        } else if (store === 'ppinc') {
          return "PpInc";
        } else if (store === 'counselling') {
          return "Counselling";
        }

        return null;
      };

      window.isChinesePage = function () {
        var store = location.pathname.split("/")[1];
        return store !== 'asteroom' && store !== 'ppinc';
      };

      window.getStoreTitle = function () {
        var store = location.pathname.split("/")[1];

        if (store === 'asteroom') {
          return "Asteroom(時間為UTC-7)";
        } else if (store === 'pptw') {
          return "台灣蒙恬(時間為UTC+8)";
        } else if (store === 'pphk') {
          return "香港蒙恬(時間為UTC+8)";
        } else if (store === 'ppinc') {
          return "PpInc(時間為UTC-7)";
        } else if (store === 'counselling') {
          return "心理諮商";
        }
      };

      if (this.session.isAuthenticated) {
        this.transitionTo(transition.targetName);
      } else {
        this.transitionTo('login');
      }
    },

    actions: {
      error(error) {
        this.toast.error(error);
        this.send('signOut');
      },

      loading(transition, route) {
        transition.send('startSpinner');
        transition.finally(() => {
          transition.send('stopSpinner');
        });
      },

      signOut() {
        if (this.session.isAuthenticated) {
          this.session.invalidate();
        }

        this.transitionTo('login');
      },

      startSpinner() {
        this.controllerFor('application').set('enableSpinner', true);
      },

      stopSpinner() {
        this.controllerFor('application').set('enableSpinner', false);
      }

    }
  });

  _exports.default = _default;
});