define("client-cma/src/order/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),

    beforeModal() {
      if (this.session.isAuthenticated) {
        this.transitionTo('/');
      }
    },

    actions: {
      homePage() {
        console.log(this.currentModel.shopifyStore);
        this.transitionTo('/' + this.currentModel.shopifyStore);
      }

    }
  });

  _exports.default = _default;
});