define("client-cma/services/order", ["exports", "client-cma/config/environment", "fetch"], function (_exports, _environment, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    async orderList4Sap(token) {
      try {
        const url = `${_environment.default.APP.rootURL}${_environment.default.APP.APINAMESPACE}get_can_transfer_to_sap_orders?shopifyStore=${window.getShopifyStore()}`;
        const response = await (0, _fetch.default)(url, {
          headers: {
            'Auth-Token': token
          }
        });

        if (response.ok) {
          let data = await response.json();
          return {
            ok: response.ok,
            data: data,
            code: response.status,
            message: response.statusText
          };
        }

        throw {
          ok: response.ok,
          data: [],
          code: response.status,
          message: response.statusText
        };
      } catch (error) {
        return error;
      }
    },

    async transfer2Sap(token, poNumbers) {
      try {
        const url = `${_environment.default.APP.rootURL}${_environment.default.APP.APINAMESPACE}transfer_order_to_sap?shopifyStore=${window.getShopifyStore()}`;
        const response = await (0, _fetch.default)(url, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
            'Auth-Token': token
          },
          body: JSON.stringify(poNumbers)
        });

        if (response.ok) {
          let data = await response.json();
          return {
            ok: response.ok,
            data: data,
            code: response.status,
            message: response.statusText
          };
        }

        throw {
          ok: response.ok,
          data: [],
          code: response.status,
          message: response.statusText
        };
      } catch (error) {
        return error;
      }
    },

    async orderList4Picking(token) {
      try {
        const url = `${_environment.default.APP.rootURL}${_environment.default.APP.APINAMESPACE}get_can_transfer_to_picking_orders?shopifyStore=${window.getShopifyStore()}`;
        const response = await (0, _fetch.default)(url, {
          headers: {
            'Auth-Token': token
          }
        });

        if (response.ok) {
          let data = await response.json();
          return {
            ok: response.ok,
            data: data,
            code: response.status,
            message: response.statusText
          };
        }

        throw {
          ok: response.ok,
          data: [],
          code: response.status,
          message: response.statusText
        };
      } catch (error) {
        return error;
      }
    },

    async transfer2picking(token, poNumbers) {
      try {
        const url = `${_environment.default.APP.rootURL}${_environment.default.APP.APINAMESPACE}transfer_order_to_picking?shopifyStore=${window.getShopifyStore()}`;
        const response = await (0, _fetch.default)(url, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
            'Auth-Token': token
          },
          body: JSON.stringify(poNumbers)
        });

        if (response.ok) {
          let data = await response.json();
          return {
            ok: response.ok,
            data: data,
            code: response.status,
            message: response.statusText
          };
        }

        throw {
          ok: response.ok,
          data: [],
          code: response.status,
          message: response.statusText
        };
      } catch (error) {
        return error;
      }
    },

    async addressList4Calibrated(token) {
      try {
        const url = `${_environment.default.APP.rootURL}${_environment.default.APP.APINAMESPACE}get_need_to_be_calibrated_orders?shopifyStore=${window.getShopifyStore()}`;
        const response = await (0, _fetch.default)(url, {
          headers: {
            'Auth-Token': token
          }
        });

        if (response.ok) {
          let data = await response.json();
          return {
            ok: response.ok,
            data: data,
            code: response.status,
            message: response.statusText
          };
        }

        throw {
          ok: response.ok,
          data: [],
          code: response.status,
          message: response.statusText
        };
      } catch (error) {
        return error;
      }
    },

    async addressDetail(token, SNS) {
      try {
        const url = `${_environment.default.APP.rootURL}${_environment.default.APP.APINAMESPACE}get_address?shopifySN=${SNS}&shopifyStore=${window.getShopifyStore()}`;
        const response = await (0, _fetch.default)(url, {
          headers: {
            'Auth-Token': token
          }
        });

        if (response.ok) {
          let data = await response.json();
          return {
            ok: response.ok,
            data: data,
            code: response.status,
            message: response.statusText
          };
        }

        throw {
          ok: response.ok,
          data: [],
          code: response.status,
          message: response.statusText
        };
      } catch (error) {
        return error;
      }
    },

    async calibrateAddress(token, address) {
      try {
        const url = `${_environment.default.APP.rootURL}${_environment.default.APP.APINAMESPACE}calibrate_order?shopifyStore=${window.getShopifyStore()}`;
        const response = await (0, _fetch.default)(url, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
            'Auth-Token': token
          },
          body: JSON.stringify(address)
        });

        if (response.ok) {
          let data = await response.json();
          return {
            ok: response.ok,
            data: data,
            code: response.status,
            message: response.statusText
          };
        }

        throw {
          ok: response.ok,
          data: [],
          code: response.status,
          message: response.statusText
        };
      } catch (error) {
        return error;
      }
    },

    async pickingListByPeriod(token, year, month) {
      try {
        const url = `${_environment.default.APP.rootURL}${_environment.default.APP.APINAMESPACE}get_picking_list?year=${year}&month=${month}&shopifyStore=${window.getShopifyStore()}`;
        const response = await (0, _fetch.default)(url, {
          headers: {
            'Auth-Token': token
          }
        });

        if (response.ok) {
          let data = await response.json();
          return {
            ok: response.ok,
            data: data,
            code: response.status,
            message: response.statusText
          };
        }

        throw {
          ok: response.ok,
          data: [],
          code: response.status,
          message: response.statusText
        };
      } catch (error) {
        return error;
      }
    },

    async pickingDetailById(token, id) {
      try {
        const url = `${_environment.default.APP.rootURL}${_environment.default.APP.APINAMESPACE}get_orders_by_picking_id?pickingId=${id}&shopifyStore=${window.getShopifyStore()}`;
        const response = await (0, _fetch.default)(url, {
          headers: {
            'Auth-Token': token
          }
        });

        if (response.ok) {
          let data = await response.json();
          return {
            ok: response.ok,
            data: data,
            code: response.status,
            message: response.statusText
          };
        }

        throw {
          ok: response.ok,
          data: [],
          code: response.status,
          message: response.statusText
        };
      } catch (error) {
        return error;
      }
    },

    async download(token, pickingId) {
      try {
        const url = `${_environment.default.APP.rootURL}${_environment.default.APP.APINAMESPACE}download_all?pickingId=${pickingId}&shopifyStore=${window.getShopifyStore()}`;
        const response = await (0, _fetch.default)(url, {
          method: 'post',
          headers: {
            'Auth-Token': token
          }
        });

        if (response.ok) {
          const name = response.headers.get('content-disposition').split(';').find(n => n.includes('filename=')).replace('filename=', '').trim();
          let blob = await response.blob();
          return {
            ok: response.ok,
            data: {
              blob: blob,
              fileName: name
            },
            code: response.status,
            message: response.statusText
          };
        }

        throw {
          ok: response.ok,
          data: [],
          code: response.status,
          message: response.statusText
        };
      } catch (error) {
        return error;
      }
    },

    async shipAndEmail(token, poNumbers) {
      try {
        const url = `${_environment.default.APP.rootURL}${_environment.default.APP.APINAMESPACE}shipped_and_email?shopifyStore=${window.getShopifyStore()}`;
        const response = await (0, _fetch.default)(url, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
            'Auth-Token': token
          },
          body: JSON.stringify(poNumbers)
        });

        if (response.ok) {
          return {
            ok: response.ok,
            data: [],
            code: response.status,
            message: response.statusText
          };
        }

        throw {
          ok: response.ok,
          data: [],
          code: response.status,
          message: response.statusText
        };
      } catch (error) {
        return error;
      }
    },

    async importOrder(token, data) {
      try {
        const url = `${_environment.default.APP.rootURL}${_environment.default.APP.APINAMESPACE}import_orders?shopifyStore=${window.getShopifyStore()}`;
        const response = await (0, _fetch.default)(url, {
          method: 'post',
          headers: {
            'Auth-Token': token
          },
          body: data,
          contentType: false,
          processData: false
        });

        if (response.ok) {
          return {
            ok: response.ok,
            data: [],
            code: response.status,
            message: response.statusText
          };
        }

        throw {
          ok: response.ok,
          data: [],
          code: response.status,
          message: response.statusText
        };
      } catch (error) {
        return error;
      }
    },

    async orderList4Shipment(token) {
      try {
        const url = `${_environment.default.APP.rootURL}${_environment.default.APP.APINAMESPACE}get_waiting_for_shipment_orders?shopifyStore=${window.getShopifyStore()}`;
        const response = await (0, _fetch.default)(url, {
          headers: {
            'Auth-Token': token
          }
        });

        if (response.ok) {
          let data = await response.json();
          return {
            ok: response.ok,
            data: data,
            code: response.status,
            message: response.statusText
          };
        }

        throw {
          ok: response.ok,
          data: [],
          code: response.status,
          message: response.statusText
        };
      } catch (error) {
        return error;
      }
    },

    async orderList4Return(token, startOfMonth, endOfMonth) {
      try {
        const url = `${_environment.default.APP.rootURL}${_environment.default.APP.APINAMESPACE}get_waiting_for_return_orders?startDate=${startOfMonth}&endDate=${endOfMonth}&shopifyStore=${window.getShopifyStore()}`;
        const response = await (0, _fetch.default)(url, {
          headers: {
            'Auth-Token': token
          }
        });

        if (response.ok) {
          let data = await response.json();
          return {
            ok: response.ok,
            data: data,
            code: response.status,
            message: response.statusText
          };
        }

        throw {
          ok: response.ok,
          data: [],
          code: response.status,
          message: response.statusText
        };
      } catch (error) {
        return error;
      }
    },

    orderList4ReturnByStatus(token, data) {
      const url = `${_environment.default.APP.rootURL}${_environment.default.APP.APINAMESPACE}get_waiting_for_return_orders?shopifyStore=${window.getShopifyStore()}`;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        $.ajax({
          url: url,
          type: 'GET',
          data: data,
          dataType: 'JSON',
          beforeSend: function (xhr) {
            xhr.setRequestHeader("Auth-Token", token);
            xhr.setRequestHeader('Cache-Control', 'no-cache, no-store, must-revalidate');
            xhr.setRequestHeader('Pragma', 'no-cache');
            xhr.setRequestHeader('Expires', '0');
          }
        }).then((result, status, xhr) => {
          resolve({
            ok: true,
            data: result,
            code: xhr.status,
            message: xhr.statusText
          });
        }, xhr => {
          reject({
            ok: false,
            data: [],
            code: xhr.status,
            message: xhr.statusText
          });
        });
      });
    },

    orderList4All(token, data) {
      const url = `${_environment.default.APP.rootURL}${_environment.default.APP.APINAMESPACE}get_all_orders?shopifyStore=${window.getShopifyStore()}`;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        $.ajax({
          url: url,
          type: 'GET',
          data: data,
          dataType: 'JSON',
          beforeSend: function (xhr) {
            xhr.setRequestHeader("Auth-Token", token);
            xhr.setRequestHeader('Cache-Control', 'no-cache, no-store, must-revalidate');
            xhr.setRequestHeader('Pragma', 'no-cache');
            xhr.setRequestHeader('Expires', '0');
          }
        }).then((result, status, xhr) => {
          resolve({
            ok: true,
            data: result,
            code: xhr.status,
            message: xhr.statusText
          });
        }, xhr => {
          reject({
            ok: false,
            data: [],
            code: xhr.status,
            message: xhr.statusText
          });
        });
      });
    },

    getStripeOrderList(token, data) {
      const url = `${_environment.default.APP.rootURL}${_environment.default.APP.APINAMESPACE}get_stripe_orders`;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        $.ajax({
          url: url,
          type: 'GET',
          data: data,
          dataType: 'JSON',
          beforeSend: function (xhr) {
            xhr.setRequestHeader("Auth-Token", token);
            xhr.setRequestHeader('Cache-Control', 'no-cache, no-store, must-revalidate');
            xhr.setRequestHeader('Pragma', 'no-cache');
            xhr.setRequestHeader('Expires', '0');
          }
        }).then((result, status, xhr) => {
          resolve({
            ok: true,
            data: result,
            code: xhr.status,
            message: xhr.statusText
          });
        }, xhr => {
          reject({
            ok: false,
            data: [],
            code: xhr.status,
            message: xhr.statusText
          });
        });
      });
    },

    products(token, data) {
      const url = `${_environment.default.APP.rootURL}${_environment.default.APP.APINAMESPACE}shopify_product?shopifyStore=${window.getShopifyStore()}`;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        $.ajax({
          url: url,
          type: 'GET',
          data: data,
          dataType: 'JSON',
          beforeSend: function (xhr) {
            xhr.setRequestHeader("Auth-Token", token);
            xhr.setRequestHeader('Cache-Control', 'no-cache, no-store, must-revalidate');
            xhr.setRequestHeader('Pragma', 'no-cache');
            xhr.setRequestHeader('Expires', '0');
          }
        }).then((result, status, xhr) => {
          resolve({
            ok: true,
            data: result,
            code: xhr.status,
            message: xhr.statusText
          });
        }, xhr => {
          reject({
            ok: false,
            data: [],
            code: xhr.status,
            message: xhr.statusText
          });
        });
      });
    },

    orderList4Shipped(token, data) {
      const url = `${_environment.default.APP.rootURL}${_environment.default.APP.APINAMESPACE}get_shipped_orders?shopifyStore=${window.getShopifyStore()}`;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        $.ajax({
          url: url,
          type: 'GET',
          data: data,
          dataType: 'JSON',
          beforeSend: function (xhr) {
            xhr.setRequestHeader("Auth-Token", token);
            xhr.setRequestHeader('Cache-Control', 'no-cache, no-store, must-revalidate');
            xhr.setRequestHeader('Pragma', 'no-cache');
            xhr.setRequestHeader('Expires', '0');
          }
        }).then((result, status, xhr) => {
          resolve({
            ok: true,
            data: result,
            code: xhr.status,
            message: xhr.statusText
          });
        }, xhr => {
          reject({
            ok: false,
            data: [],
            code: xhr.status,
            message: xhr.statusText
          });
        });
      });
    },

    async checkRequestPaymentType(token, file) {
      const url = `${_environment.default.APP.rootURL}${_environment.default.APP.APINAMESPACE}request_payment_type_check`;
      const formData = new FormData();
      formData.append("file", file);
      const response = await (0, _fetch.default)(url, {
        method: 'POST',
        body: formData,
        headers: {
          'Auth-Token': token
        }
      });

      if (response.ok) {
        let data = await response.json();
        return {
          ok: response.ok,
          data
        };
      }
    },

    async importAsteroomRequestPaymentToSap(token, file) {
      const url = `${_environment.default.APP.rootURL}${_environment.default.APP.APINAMESPACE}import_asteroom_request_payment?isAP=false`;
      const formData = new FormData();
      formData.append("file", file);
      const response = await (0, _fetch.default)(url, {
        method: 'POST',
        body: formData,
        headers: {
          'Auth-Token': token
        }
      });

      if (response.ok) {
        let data = await response.json();
        return {
          ok: response.ok,
          data
        };
      }
    },

    async importAsteroomRequestPaymentToSapAP(token, file) {
      const url = `${_environment.default.APP.rootURL}${_environment.default.APP.APINAMESPACE}import_asteroom_request_payment?isAP=true`;
      const formData = new FormData();
      formData.append("file", file);
      const response = await (0, _fetch.default)(url, {
        method: 'POST',
        body: formData,
        headers: {
          'Auth-Token': token
        }
      });

      if (response.ok) {
        let data = await response.json();
        return {
          ok: response.ok,
          data
        };
      }
    },

    async importCounsellingToArInvoice(token, file) {
      const url = `${_environment.default.APP.rootURL}${_environment.default.APP.APINAMESPACE}import_counselling_ar_invoice`;
      const formData = new FormData();
      formData.append("file", file);
      const response = await (0, _fetch.default)(url, {
        method: 'POST',
        body: formData,
        headers: {
          'Auth-Token': token
        }
      });

      if (response.ok) {
        let data = await response.json();
        return {
          ok: response.ok,
          data
        };
      }
    },

    async importAsteroomOrderToSapAP(token, file, postingDate) {
      const url = `${_environment.default.APP.rootURL}${_environment.default.APP.APINAMESPACE}import_asteroom_order?postingDate=${postingDate}`;
      const formData = new FormData();
      formData.append("file", file);
      const response = await (0, _fetch.default)(url, {
        method: 'POST',
        body: formData,
        headers: {
          'Auth-Token': token
        }
      });

      if (response.ok) {
        let data = await response.json();
        return {
          ok: response.ok,
          data
        };
      }
    },

    async importAsteroomOrderToSapAr(token, file, postingDate) {
      const url = `${_environment.default.APP.rootURL}${_environment.default.APP.APINAMESPACE}import_asteroom_order?postingDate=${postingDate}&isAr=true`;
      const formData = new FormData();
      formData.append("file", file);
      const response = await (0, _fetch.default)(url, {
        method: 'POST',
        body: formData,
        headers: {
          'Auth-Token': token
        }
      });

      if (response.ok) {
        let data = await response.json();
        return {
          ok: response.ok,
          data
        };
      }
    },

    async importAsteroomOrderToSapRWC008(token, file) {
      const url = `${_environment.default.APP.rootURL}${_environment.default.APP.APINAMESPACE}import_asteroom_order_rwc008`;
      const formData = new FormData();
      formData.append("file", file);
      const response = await (0, _fetch.default)(url, {
        method: 'POST',
        body: formData,
        headers: {
          'Auth-Token': token
        }
      });

      if (response.ok) {
        let data = await response.json();
        return {
          ok: response.ok,
          data
        };
      }
    },

    async importAmazonFBAOrderToSapAP(token, file, isAsteroom) {
      const url = `${_environment.default.APP.rootURL}${_environment.default.APP.APINAMESPACE}import_amazon_fba?isAsteroom=${isAsteroom ? 'true' : 'false'}`;
      const formData = new FormData();
      formData.append("file", file);
      const response = await (0, _fetch.default)(url, {
        method: 'POST',
        body: formData,
        headers: {
          'Auth-Token': token
        }
      });

      if (response.ok) {
        let data = await response.json();
        return {
          ok: response.ok,
          data
        };
      }
    },

    async importInvoiceToSapAr(token, file, isAsteroom) {
      const url = `${_environment.default.APP.rootURL}${_environment.default.APP.APINAMESPACE}import_invoice_to_sap_ar`;
      const formData = new FormData();
      formData.append("file", file);
      const response = await (0, _fetch.default)(url, {
        method: 'POST',
        body: formData,
        headers: {
          'Auth-Token': token
        }
      });

      if (response.ok) {
        let data = await response.json();
        return {
          ok: response.ok,
          data
        };
      } else {
        let data = await response.json();
        return {
          ok: false,
          data
        };
      }
    },

    async downloadPickingList(token, SNS) {
      try {
        const url = `${_environment.default.APP.rootURL}${_environment.default.APP.APINAMESPACE}download_picking_list?shopifyStore=${window.getShopifyStore()}`;
        const response = await (0, _fetch.default)(url, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
            'Auth-Token': token
          },
          body: JSON.stringify(SNS)
        });

        if (response.ok) {
          const name = response.headers.get('content-disposition').split(';').find(n => n.includes('filename=')).replace('filename=', '').trim();
          let blob = await response.blob();
          return {
            ok: response.ok,
            data: {
              blob: blob,
              fileName: name
            },
            code: response.status,
            message: response.statusText
          };
        }

        throw {
          ok: response.ok,
          data: [],
          code: response.status,
          message: response.statusText
        };
      } catch (error) {
        return error;
      }
    },

    async mailAndExport2Sap(token, SNS) {
      try {
        const url = `${_environment.default.APP.rootURL}${_environment.default.APP.APINAMESPACE}send_thank_you_letter_and_export_to_sap?shopifyStore=${window.getShopifyStore()}`;
        const response = await (0, _fetch.default)(url, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
            'Auth-Token': token
          },
          body: JSON.stringify(SNS)
        });

        if (response.ok) {
          return {
            ok: response.ok,
            data: [],
            code: response.status,
            message: response.statusText
          };
        }

        throw {
          ok: response.ok,
          data: [],
          code: response.status,
          message: response.statusText
        };
      } catch (error) {
        return error;
      }
    },

    async freehardwareToSap(token, body) {
      try {
        const url = `${_environment.default.APP.rootURL}${_environment.default.APP.APINAMESPACE}free_hardware_to_asteroom`;
        const response = await (0, _fetch.default)(url, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
            'Auth-Token': token
          },
          body: JSON.stringify(body)
        });

        if (response.ok) {
          let data = await response.json();
          return {
            ok: response.ok,
            data: data,
            code: response.status,
            message: response.statusText
          };
        }

        throw {
          ok: response.ok,
          data: [],
          code: response.status,
          message: response.statusText
        };
      } catch (error) {
        return error;
      }
    },

    async freehardwareToProcessingOrders(token, body) {
      try {
        const url = `${_environment.default.APP.rootURL}${_environment.default.APP.APINAMESPACE}free_hardware_to_processing_orders`;
        const response = await (0, _fetch.default)(url, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
            'Auth-Token': token
          },
          body: JSON.stringify(body)
        });

        if (response.ok) {
          let data = await response.json();
          return {
            ok: response.ok,
            data: data,
            code: response.status,
            message: response.statusText
          };
        }

        throw {
          ok: response.ok,
          data: [],
          code: response.status,
          message: response.statusText
        };
      } catch (error) {
        return error;
      }
    },

    async adjustInventory(token, body) {
      try {
        const url = `${_environment.default.APP.rootURL}${_environment.default.APP.APINAMESPACE}adjust_inventory?shopifyStore=${window.getShopifyStore()}`;
        const response = await (0, _fetch.default)(url, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
            'Auth-Token': token
          },
          body: JSON.stringify(body)
        });

        if (response.ok) {
          let data = await response.json();
          return {
            ok: response.ok,
            data: data,
            code: response.status,
            message: response.statusText
          };
        }

        throw {
          ok: response.ok,
          data: [],
          code: response.status,
          message: response.statusText
        };
      } catch (error) {
        return error;
      }
    },

    async setReturnOrders(token, SNS) {
      try {
        const url = `${_environment.default.APP.rootURL}${_environment.default.APP.APINAMESPACE}set_to_waiting_for_return?shopifyStore=${window.getShopifyStore()}`;
        const response = await (0, _fetch.default)(url, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
            'Auth-Token': token
          },
          body: JSON.stringify(SNS)
        });

        if (response.ok) {
          return {
            ok: response.ok,
            data: [],
            code: response.status,
            message: response.statusText
          };
        }

        throw {
          ok: response.ok,
          data: [],
          code: response.status,
          message: response.statusText
        };
      } catch (error) {
        return error;
      }
    },

    async sendEmail(token, data) {
      try {
        const url = `${_environment.default.APP.rootURL}${_environment.default.APP.APINAMESPACE}mail?shopifyStore=${window.getShopifyStore()}`;
        const response = await (0, _fetch.default)(url, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
            'Auth-Token': token
          },
          body: JSON.stringify(data)
        });

        if (response.ok) {
          return {
            ok: response.ok,
            data: [],
            code: response.status,
            message: response.statusText
          };
        }

        console.log(response);
        throw {
          ok: response.ok,
          data: [],
          code: response.status,
          message: response.statusText
        };
      } catch (error) {
        return error;
      }
    },

    async confirmReturnedOrders(token, SNS) {
      try {
        const url = `${_environment.default.APP.rootURL}${_environment.default.APP.APINAMESPACE}received_products_return?shopifyStore=${window.getShopifyStore()}`;
        const response = await (0, _fetch.default)(url, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
            'Auth-Token': token
          },
          body: JSON.stringify(SNS)
        });

        if (response.ok) {
          return {
            ok: response.ok,
            data: [],
            code: response.status,
            message: response.statusText
          };
        }

        throw {
          ok: response.ok,
          data: [],
          code: response.status,
          message: response.statusText
        };
      } catch (error) {
        return error;
      }
    },

    async packageListByOrder(token, SN) {
      try {
        const url = `${_environment.default.APP.rootURL}${_environment.default.APP.APINAMESPACE}get_packages?shopifySN=${SN}&shopifyStore=${window.getShopifyStore()}`;
        const response = await (0, _fetch.default)(url, {
          headers: {
            'Auth-Token': token
          }
        });

        if (response.ok) {
          let data = await response.json();
          return {
            ok: response.ok,
            data: data,
            code: response.status,
            message: response.statusText
          };
        }

        throw {
          ok: response.ok,
          data: [],
          code: response.status,
          message: response.statusText
        };
      } catch (error) {
        return error;
      }
    },

    async deleteOrder(token, SN) {
      try {
        const url = `${_environment.default.APP.rootURL}${_environment.default.APP.APINAMESPACE}cancel?shopifyStore=${window.getShopifyStore()}`;
        const response = await (0, _fetch.default)(url, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
            'Auth-Token': token
          },
          body: JSON.stringify({
            shopifySN: SN
          })
        });

        if (response.ok) {
          return {
            ok: response.ok,
            data: [],
            code: response.status,
            message: response.statusText
          };
        }

        throw {
          ok: response.ok,
          data: [],
          code: response.status,
          message: response.statusText
        };
      } catch (error) {
        return error;
      }
    },

    async proceedCreditNote(token, SN) {
      try {
        const url = `${_environment.default.APP.rootURL}${_environment.default.APP.APINAMESPACE}set_to_return_in_sap?shopifyStore=${window.getShopifyStore()}`;
        const response = await (0, _fetch.default)(url, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
            'Auth-Token': token
          },
          body: JSON.stringify({
            shopifySN: SN
          })
        });

        if (response.ok) {
          let data = await response.json();
          return {
            ok: response.ok,
            data: data,
            code: response.status,
            message: response.statusText
          };
        }

        throw {
          ok: response.ok,
          data: [],
          code: response.status,
          message: response.statusText
        };
      } catch (error) {
        return error;
      }
    },

    async editOrder(token, data) {
      try {
        const url = `${_environment.default.APP.rootURL}${_environment.default.APP.APINAMESPACE}add_tracking_number?shopifyStore=${window.getShopifyStore()}`;
        const response = await (0, _fetch.default)(url, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
            'Auth-Token': token
          },
          body: JSON.stringify(data)
        });

        if (response.ok) {
          return {
            ok: response.ok,
            data: [],
            code: response.status,
            message: response.statusText
          };
        }

        throw {
          ok: response.ok,
          data: [],
          code: response.status,
          message: response.statusText
        };
      } catch (error) {
        return error;
      }
    }

  });

  _exports.default = _default;
});