define("client-cma/src/order/stripe/route", ["exports", "client-cma/utils/Data"], function (_exports, _Data) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    order: Ember.inject.service(),
    toast: Ember.inject.service(),

    async getStripeOrderList(token, queryParams) {
      let dataSource = [];

      try {
        const response = await this.order.getStripeOrderList(token, queryParams);

        if (response.ok) {
          dataSource = response.data;
        } else {
          throw response;
        }

        return dataSource;
      } catch (error) {
        if (error.code === 401) {
          await this.session.invalidate();
          this.transitionTo('/');
        } else if (error.code === 403) {
          this.transitionTo('/');
        } else {
          this.toast.error(error.message);
        }
      }
    },

    async model() {
      const token = this.session.data.authenticated.token;
      const {
        columns,
        chineseColumns
      } = this;
      let isChinesePage = window.isChinesePage();

      var startDateAndEndDate = _Data.default.getStartDateAndEndDate(isChinesePage, moment(new Date()));

      let queryParams = {
        startDate: startDateAndEndDate.startDate,
        endDate: startDateAndEndDate.endDate
      }; // if(isChinesePage){
      //     queryParams = {
      //         startDate: Data.startOfTaipeiTimeZone('month'),
      //         endDate: Data.endOfTaipeiTimeZone('month')
      //     }
      // }

      const dataSource = await this.getStripeOrderList(token, queryParams);
      return {
        options: {
          data: dataSource,
          columns: chineseColumns,
          order: [] // select: { style: 'single', selector: 'td:not(:first-child)', }

        },
        datetimeOptions: {
          format: 'YYYY-MM',
          viewMode: 'months',
          timezone: isChinesePage ? 'Asia/Taipei' : 'America/Los_Angeles'
        },
        storeTitle: window.getStoreTitle()
      };
    },

    setupController(controller, model) {
      controller.set('filter', '');
      controller.set('model', model);
      setTimeout(() => {
        // const colors = ['#7f58af', 'blue', 'green']
        const colors = ["#ac48a3", "#67bd3f", "#8c54c8", "#a1ba3c", "#d177e2", "#3b8e35", "#db47a6", "#5cc477", "#e03b6a", "#5ac1a1", "#d94a34", "#3abbcc", "#e18c30", "#6070d2", "#c7a73a", "#6495d2", "#bc5b2a", "#33815b", "#b6457b", "#678429", "#cd8ecb", "#5e874a", "#7e5692", "#b0b16b", "#a0485d", "#7b712b", "#e5828b", "#99662e", "#b44f46", "#de9a6c"];
        const stripeSummaryList = [...document.querySelectorAll('.stripe-summary')];
        const idToDom = {};
        stripeSummaryList.forEach(s => {
          const sapId = s.dataset.sapid;

          if (idToDom[sapId]) {
            idToDom[sapId] = [...idToDom[sapId], s];
          } else {
            idToDom[sapId] = [s];
          }
        });
        Object.values(idToDom).forEach(arr => {
          const color = colors.pop();
          arr.forEach(a => {
            a.style.color = 'white';
            a.style['font-size'] = '2rem';
            a.parentElement.style['background-color'] = color;
          });
        }); // console.log(idToDom)
      }, 1);
    },

    actions: {
      async getStripeOrderList(data, dt) {
        const token = this.session.data.authenticated.token;
        const isChinesePage = window.isChinesePage();

        var startDateAndEndDate = _Data.default.getStartDateAndEndDate(isChinesePage, data.date);

        let queryParams = {
          startDate: startDateAndEndDate.startDate,
          endDate: startDateAndEndDate.endDate
        };

        if (!_Data.default.isEmptyString(data.filter)) {
          queryParams = {};
          queryParams.keyword = data.filter;
        }

        const dataSource = await this.getStripeOrderList(token, queryParams);
        dt.clear().rows.add(dataSource).draw();
        dt.rows().deselect();
        dt.columns.adjust();
        $('table thead .details-control').css('width', '15px');
      }

    },
    columns: [],
    chineseColumns: [{
      data: null,
      orderable: false,
      visible: false,
      className: 'details-control',
      render: () => '<i class="fa fa-plus" aria-hidden="true"></i>'
    }, {
      data: 'orderId',
      title: '訂單號碼',
      render: (data, type, row, meta) => {
        return `${row.orderId}<br>${row.email}<br>時間：${moment(row.purchaseDate).tz('Asia/Taipei').format('YYYY-MM-DD HH:mm:ss')}`;
      }
    }, {
      data: 'sapId',
      title: 'Sap Id',
      render: (data, type, row, meta) => {
        if (data == null && row.currency !== 'TWD') {
          return `<div style='color:red'>每星期一早上9:00彙整匯入</div>`;
        } else if (data && row.currency !== 'TWD') {
          return `<div class='stripe-summary' data-sapid='${data}'>${data}</div>`;
        } else {
          return data;
        }
      }
    }, {
      data: 'detail',
      orderable: false,
      title: '內容',
      render: (data, type, row, meta) => {
        const d = JSON.parse(data);

        if (d.Currency.toUpperCase() === 'TWD' && d.CarrierType === -1) {
          d.CarrierType = 1;
        }

        let content = '';

        for (let i = 0; i < d.PurchaseItems.length; i++) {
          const purchaseItem = d.PurchaseItems[i];
          content += `<div class='stripe-sub-detail'>`;
          content += `<div class='stripe-sub-detail-header'>購買商品</div>`;
          content += `<div>SKU：${purchaseItem.SAPItemCode.toUpperCase()}</div>`;
          content += `<div>商品名：${purchaseItem.ProductName}</div>`;
          content += `<div>數量：${purchaseItem.Quantity}</div>`;
          content += `<div>單價：${purchaseItem.UnitPrice} ${d.Currency.toUpperCase()}</div>`;
          content += `<div>總價：${d.Amount} ${d.Currency.toUpperCase()}</div>`;
          content += `</div>`;
        }

        if (d.CarrierType !== -1) {
          content += `<div class='stripe-sub-detail'>`;
          content += `<div class='stripe-sub-detail-header'>發票</div>`;

          if (d.CarrierType === 5) {
            content += `<div>載具類別：無載具(捐贈)</div>`;
            content += `<div>捐贈代碼：${d.DonateToUnitCode}</div>`;
          } else if (d.CarrierType === 1) {
            content += `<div>載具類別：綠界電子發票載具</div>`;
          } else if (d.CarrierType === 2) {
            content += `<div>載具類別：自然人憑證</div>`;
            content += `<div>自然人憑證號碼：${d.NationalPersonNumber}</div>`;
          } else if (d.CarrierType === 3) {
            content += `<div>載具類別：手機條碼</div>`;
            content += `<div>手機條碼：${d.MobileBarcodeCarrier}</div>`;
          } else if (d.CarrierType === 0) {
            content += `<div>載具類別：無載具(列印電子發票)</div>`;
            if (d.CompanyName) content += `<div>姓名：${d.CompanyName}</div>`;
            if (d.CompanyPhone) content += `<div>電話：${d.CompanyPhone}</div>`;
            if (d.CompanyAddress) content += `<div>地址：${d.CompanyAddress}</div>`;
          } else if (d.CarrierType === 4) {
            content += `<div>載具類別：無載具(公司行號)</div>`;
            if (d.CompanyName) content += `<div>公司名稱：${d.CompanyName}</div>`;
            if (d.CompanyPhone) content += `<div>公司電話：${d.CompanyPhone}</div>`;
            if (d.CompanyAddress) content += `<div>公司地址：${d.CompanyAddress}</div>`;
            if (d.CompanyIdentifier) content += `<div>統一編號：${d.CompanyIdentifier}</div>`;
          }

          if (d.Note) {
            content += `<div>備註：${d.Note}</div>`;
          }

          content += `</div>`;
        }

        let html = `<div class='stripe-detail'>${content}</div>`;
        return html;
      }
    }]
  });

  _exports.default = _default;
});