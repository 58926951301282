define("client-cma/components/datatable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    order: Ember.inject.service(),
    router: Ember.inject.service(),
    toast: Ember.inject.service(),
    swal: Ember.inject.service(),
    tagName: 'table',
    classNames: ['table', 'table-striped', 'table-bordered', 'nowrap', 'collapsed'],
    attributeBindings: ['width'],
    width: '100%',
    isWritable: Ember.computed.alias('session.data.authenticated.writable'),
    token: Ember.computed.alias('session.data.authenticated.token'),

    init() {
      this._super(...arguments);
    },

    didInsertElement() {
      const {
        dataSrc,
        columns,
        detailSrc,
        detailCols
      } = this;
      const {
        elementId,
        options,
        dblClick,
        btnClick,
        select,
        deSelect,
        mail,
        edit,
        cancel,
        proceed,
        isWritable,
        token
      } = this;
      let table = $(`#${elementId}`).DataTable({
        initComplete: function () {
          this.api().buttons().enable(false);

          if (isWritable && options.defaultSelect) {
            this.api().row({
              order: 'current'
            }, 0).select();
            this.api().buttons().enable(true);
          }
        },
        dom: options.dom || "rti<'row'<'col-md-12 text-center mt-3'B>>",
        data: options.data || [],
        columns: options.columns || [],
        columnDefs: options.columnDefs || [],
        select: options.select || {
          style: 'single'
        },
        order: options.order || [],
        buttons: options.buttons || [],
        paging: false,
        scrollX: true,
        createdRow: (row, data, dataIndex) => {
          try {
            if (Number(data.riskScore) > 0) {
              $(row).css('background', '#f46e6e');
            }
          } catch (e) {
            console.log(e);
          }
        }
      });
      table.on('click', 'td.details-control', e => {
        const row = table.row(e.currentTarget);
        const trackingCom = row.data().trackingCompany.toLowerCase();
        const SN = row.data().shopifySN;

        if (row.child.isShown()) {
          $(row.node()).find('.details-control [data-fa-i2svg]').replaceWith('<i class="fa fa-plus" aria-hidden="true"></i>');
          row.nodes().to$().removeClass('shown');
          row.child.hide();
          $(`#${SN}`).DataTable().destroy(true);
        } else {
          $(row.node()).find('.details-control [data-fa-i2svg]').replaceWith('<i class="fa fa-minus" aria-hidden="true"></i>');
          row.nodes().to$().addClass('shown');
          row.child(this.tableFormat(SN)).show();
          this.buildTable(token, SN, trackingCom);
        }
      }).on('click', '#send-email', e => {
        const row = table.row($(e.currentTarget).parent());

        if (mail && Ember.typeOf(mail) === 'function') {
          mail(table, row.data());
        }
      }).on('click', '#credit-note', e => {
        const row = table.row($(e.currentTarget).parent());

        if (proceed && Ember.typeOf(proceed) === 'function') {
          proceed(table, row.data());
        }
      }).on('click', '#delete-order', e => {
        const row = table.row($(e.currentTarget).parent());

        if (cancel && Ember.typeOf(cancel) === 'function') {
          this.swal.open({
            title: 'Are you sure?',
            showCancelButton: true
          }).then(({
            value
          }) => {
            if (value) cancel(table, row.data());
          });
        }
      }).on('click', '#edit-number', e => {
        const row = table.row($(e.currentTarget).parent());

        if (edit && Ember.typeOf(edit) === 'function') {
          edit(table, row.data());
        }
      }).on('click', '.fa-sticky-note-wrapper', e => {
        alert(`備註：${e.target.dataset.tooltip}`);
      }).on('select', (e, dt, type, index) => {
        dt.rows('.non-select').nodes().to$().removeClass('selected');
        const count = dt.rows('.selected').count();
        this.buttonsEnabled(dt, count > 0);
        this.infoSelected(count);

        if (select && Ember.typeOf(select) === 'function') {
          select(dt, index);
        }

        if (window.getShopifyStore() === 'PpHk' && count > 0) {
          let sapId = dt.rows('.selected').data()[0].sapId;

          if (sapId) {
            $('.sap-or-mail > span').text('出貨(寄信)');
          } else {
            $('.sap-or-mail > span').text('匯入SAP');
          }
        }
      }).on('deselect', (e, dt, type, index) => {
        const count = dt.rows('.selected').count();
        this.buttonsEnabled(dt, count > 0);
        this.selectAllChecked(count > 0);
        this.infoSelected(count);

        if (deSelect && Ember.typeOf(deSelect) === 'function') {
          deSelect(dt);
        }

        if (window.getShopifyStore() === 'PpHk') {
          $('.sap-or-mail > span').text('匯入SAP/出貨(寄信)');
        }
      }).on('dblclick', 'tr', function (e) {
        if (dblClick && Ember.typeOf(dblClick) === 'function') {
          table.rows('.selected').nodes().to$().removeClass('selected');
          table.rows(e.currentTarget).nodes().to$().addClass('selected');
          dblClick(table.rows(e.currentTarget).data().toArray());
        }
      }.bind(this));
      table.buttons().container().removeClass('btn-group');
      table.buttons().each(button => {
        $(button.node).removeClass('btn-secondary');
      });
      table.buttons().action(function (e, dt, node, config) {
        if (btnClick && Ember.typeOf(btnClick) === 'function') {
          this.swal.open({
            title: 'Are you sure?',
            showCancelButton: true
          }).then(({
            value
          }) => {
            if (value) btnClick(dt, config);
          });
        }
      }.bind(this));
      Ember.run.later(table, () => {
        table.columns.adjust();
        $('table thead .details-control').css('width', '15px');
      }, 2);
    },

    buttonsEnabled(dt, isEnabled) {
      if (this.isWritable) dt.buttons().enable(isEnabled);
    },

    selectAllChecked(isSelected) {
      $('.dt-checkboxes-select-all input[type="checkbox"]').prop('checked', isSelected);
    },

    infoSelected(num) {
      const infoString = num > 1 ? `${num} rows selected` : num == 1 ? `1 row selected` : '';
      const $infoStatus = $('div.dataTables_info');
      const existing = $infoStatus.children($('span.select-info'));

      if (existing.length) {
        $('span.select-info').text(infoString);
      } else {
        let output = $('<span class="select-info"/>').append($('<span class="select-item"/>')).append(infoString);
        if (infoString.length !== 0) $infoStatus.append(output);
      }
    },

    tableFormat(id) {
      return `<table id='${id}' class='table table-striped table-bordered' cellpadding='5' cellspacing='1' border='1' style='border-top:1px solid #dee2e6;'></table>`;
    },

    async packageListByOrder(token, SN) {
      let dataSource = [];

      try {
        const response = await this.order.packageListByOrder(token, SN);

        if (response.ok) {
          dataSource = response.data;
        } else {
          throw response;
        }
      } catch (error) {
        if (error.code === 401) {
          await this.session.invalidate();
          this.router.transitionTo('/');
        } else if (error.code === 403) {
          this.router.transitionTo('/');
        } else {
          this.toast.error(error.message);
        }
      }

      return dataSource;
    },

    async buildTable(token, SN, trackingCom) {
      const {
        options
      } = this;
      let dataSrc = []; // if(trackingCom.indexOf('fedex') !== -1) dataSrc = await this.packageListByOrder(token, SN);

      dataSrc = await this.packageListByOrder(token, SN);
      console.log('test');
      let detail = $(`#${SN}`).DataTable({
        // dom: "<'row'<'col-md-12 mb-2'B>>t",
        dom: 't',
        data: dataSrc,
        columns: this.detailCols,
        scrollX: '1000px',
        scrollY: '200px',
        select: false // buttons: options.detailButtons || [],

      });
      $(detail.table().header()).find('tr').addClass('bg-white'); // detail.buttons().nodes().removeClass('btn-secondary');
      // if(detail.row().data()) detail.buttons().disable();
      // detail.buttons().action(function(e, dt, node, config) {
      //     if(childBtnClick && typeOf(childBtnClick) === 'function') {
      //         childBtnClick(dt, config);
      //     }
      // }.bind(this));
    },

    dataSrc: [{
      "shopifySN": "2528919945379",
      "idDisplay": "#1009",
      "dueDate": "2020-06-19T05:49:07Z",
      "email": "henry.yu@penpower.com.tw",
      "shippedAddress": "7383 Swann Street San Jose CA US 95123",
      "trackingCompany": "Fedex",
      "sapId": 1201797,
      "isShipped": true,
      "note": null,
      "isDownloaded": true,
      "mailStatus": "Failed",
      "cancelStatus": 'Waiting for Return',
      "isReturnInSap": true,
      "trackingNumber": null
    }, {
      "shopifySN": "2528919945380",
      "idDisplay": "#1010",
      "dueDate": "2020-06-19T05:49:07Z",
      "email": "henry.yu@penpower.com.tw",
      "shippedAddress": "7383 Swann Street San Jose CA US 95123",
      "trackingCompany": "Fedex",
      "sapId": 1201798,
      "isShipped": false,
      "note": null,
      "isDownloaded": false,
      "mailStatus": "Failed",
      "cancelStatus": 'Waiting for Return',
      "isReturnInSap": false,
      "trackingNumber": '794619806773'
    }, {
      "shopifySN": "2528919945383",
      "idDisplay": "#1013",
      "dueDate": "2020-06-19T05:49:07Z",
      "email": "henry.yu@penpower.com.tw",
      "shippedAddress": "7383 Swann Street San Jose CA US 95123",
      "trackingCompany": "Fedex",
      "sapId": 1201798,
      "isShipped": false,
      "note": null,
      "isDownloaded": false,
      "mailStatus": "Failed",
      "cancelStatus": 'Returned',
      "isReturnInSap": false,
      "trackingNumber": '794619806772'
    }, {
      "shopifySN": "2528919945383",
      "idDisplay": "#1012",
      "dueDate": "2020-06-19T05:49:07Z",
      "email": "henry.yu@penpower.com.tw",
      "shippedAddress": "7383 Swann Street San Jose CA US 95123",
      "trackingCompany": "Fedex",
      "sapId": 1201799,
      "isShipped": true,
      "note": null,
      "isDownloaded": true,
      "mailStatus": "Failed",
      "cancelStatus": 'Returned',
      "isReturnInSap": true,
      "trackingNumber": '794619806771'
    }, {
      "shopifySN": "2528919945381",
      "idDisplay": "#1011",
      "dueDate": "2020-06-19T05:49:07Z",
      "email": "henry.yu@penpower.com.tw",
      "shippedAddress": "7383 Swann Street San Jose CA US 95123",
      "trackingCompany": "Fedex",
      "sapId": 1201799,
      "isShipped": true,
      "note": null,
      "isDownloaded": true,
      "mailStatus": "Failed",
      "cancelStatus": 'Returned',
      "isReturnInSap": true,
      "trackingNumber": '794619806770'
    }],
    columns: [{
      data: null,
      orderable: false,
      className: 'details-control',
      render: () => '<i class="fa fa-plus" aria-hidden="true"></i>'
    }, {
      data: null,
      orderable: false,
      checkboxes: {
        selectRow: true,
        selectAll: true,
        selectAllRender: '<input type="checkbox" class="dt-checkboxes">'
      }
    }, {
      data: 'shopifySN',
      title: 'Shopify SN',
      visible: false
    }, {
      data: 'idDisplay',
      title: 'ID NO'
    }, {
      data: 'dueDate',
      title: 'Due Date',
      render: (data, type, row, meta) => {
        return moment(data).tz('America/Los_Angeles').format('YYYY-MM-DD HH:mm:ss');
      }
    }, {
      data: 'email',
      title: 'Email'
    }, {
      data: 'shippedAddress',
      title: 'Shipping Address'
    }, {
      data: 'sapId',
      title: 'Sap Id',
      defaultContent: '-'
    }, {
      data: 'trackingCompany',
      title: 'Tracking<br>Company',
      defaultContent: 'Fedex',
      className: 'text-center'
    }, {
      data: 'isShipped',
      title: 'Ship<br/>Status',
      defaultContent: '-',
      className: 'text-center',
      render: data => {
        if (data) {
          return '<i class="fa fa-check-circle fa-lg text-success" aria-hidden="true"></i>';
        } else {
          return '<i class="fa fa-times-circle fa-lg text-danger" aria-hidden="true"></i>';
        }
      }
    }, {
      data: 'isDownloaded',
      title: 'Download<br/>Status',
      defaultContent: '-',
      className: 'text-center',
      render: data => {
        if (data) {
          return `<span class="badge badge-success">${data}</span>`;
        } else {
          return `<span class="badge badge-danger">${data}</span>`;
        }
      }
    }, {
      data: 'mailStatus',
      title: 'Mail<br/>Status',
      defaultContent: '-',
      className: 'text-center',
      render: data => {
        if (data === 'Failed') {
          return `<span class="badge badge-danger">${data}</span>`;
        } else {
          return data;
        }
      }
    }, {
      data: 'cancelStatus',
      title: 'Cancel<br/>Status',
      defaultContent: '-',
      className: 'text-center',
      render: data => {
        return `<span class="badge">${data ? data : '-'}</span>`;
      }
    }, {
      data: 'note',
      title: 'Note',
      defaultContent: '-',
      className: 'text-center'
    }],
    detailSrc: [{
      "details": [{
        "sku": "sdfsdfsd",
        "quantity": 2
      }, {
        "sku": "sdfsdfsd",
        "quantity": 2
      }, {
        "sku": "sdfsdfsd",
        "quantity": 2
      }],
      "description": "組合包",
      "trackingNumber": "794613310454"
    }, {
      "details": [{
        "sku": "sdfsdfsd",
        "quantity": 1
      }, {
        "sku": "sdfsdfsd",
        "quantity": 2
      }, {
        "sku": "sdfsdfsd",
        "quantity": 3
      }],
      "description": "組合包",
      "trackingNumber": "794613310455"
    }, {
      "details": [{
        "sku": "sdfsdfsd",
        "quantity": 1
      }, {
        "sku": "sdfsdfsd",
        "quantity": 2
      }, {
        "sku": "sdfsdfsd",
        "quantity": 1
      }],
      "description": "組合包",
      "trackingNumber": "794613310456"
    }],
    detailCols: [{
      data: 'trackingNumber',
      title: 'Tracking number',
      width: '150px',
      defaultContent: '-',
      className: 'editable'
    }, {
      data: 'detail',
      title: 'Description',
      width: '300px',
      defaultContent: '-',
      className: 'description',
      render: data => {
        let details = '';

        if (data) {
          JSON.parse(data).forEach(item => {
            details += `${item.Sku} x ${item.Quantity} - ${item.Description}<br>`;
          });
          return `<span class="font-weight-bold">${details}</span>`;
        }
      }
    }, {
      data: 'note',
      title: 'Note',
      defaultContent: '-',
      className: '',
      render: data => {
        if (data) {
          try {
            const d = JSON.parse(data);
            const info = d.Info;
            const capacity = d.Capacity;

            if (d.Others == null) {
              return `${info.Length} x ${info.Width} x ${info.Height} (IN), ${info.Weight} (LB), Capacity: ${capacity}`;
            }

            const others = JSON.parse(d.Others);
            const charge = others.Charge;
            const shipmentDetail = others.ShipmentDetail;
            return `<span class='note-title'>Estimated shipping charges:</span> ${charge}<br>` + `<span class='note-title'>Is One Rate:</span> ${shipmentDetail.IsOneRate}<br>` + `<span class='note-title'>PackagingType:</span> ${shipmentDetail.PackagingType}<br>` + `<span class='note-title'>ServiceType:</span> ${shipmentDetail.ServiceType}<br>` + `<span class='note-title'>(Length, Width, Height, Weight):</span> (${info.Length}, ${info.Width}, ${info.Height}, ${info.Weight})`;
          } catch (e) {
            return data;
          }
        }
      }
    }]
  });

  _exports.default = _default;
});