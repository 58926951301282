define("client-cma/src/order/process/route", ["exports", "client-cma/utils/Data"], function (_exports, _Data) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    order: Ember.inject.service(),
    toast: Ember.inject.service(),

    async orderList4Shipment(token) {
      let dataSource = [];

      try {
        const response = await this.order.orderList4Shipment(token);

        if (response.ok) {
          dataSource = response.data;
        } else {
          throw response;
        }

        return dataSource;
      } catch (error) {
        if (error.code === 401) {
          await this.session.invalidate();
          this.transitionTo('/');
        } else if (error.code === 403) {
          this.transitionTo('/');
        } else {
          this.toast.error(error.message);
        }
      }
    },

    async products(token) {
      let dataSource = [];

      try {
        const response = await this.order.products(token);

        if (response.ok) {
          dataSource = response.data;
        } else {
          throw response;
        }

        return dataSource;
      } catch (error) {
        if (error.code === 401) {
          await this.session.invalidate();
          this.transitionTo('/');
        } else if (error.code === 403) {
          this.transitionTo('/');
        } else {
          this.toast.error(error.message);
        }
      }
    },

    download(data) {
      let elm = document.createElement('a');
      elm.href = URL.createObjectURL(data.blob);
      elm.setAttribute('download', data.fileName);
      elm.click();
    },

    setupController: async function (controller, model) {
      // const token = this.session.data.authenticated.token;
      // model.products = await this.products(model.token)
      this._super(controller, model);

      if (model.showProduct) {
        controller.set('products', await this.products(model.token));
      }

      console.log(model); // controller.set('model', model);
      // console.log(model)
    },

    async model() {
      const token = this.session.data.authenticated.token;
      const {
        buttons,
        columns,
        chineseColumns,
        chinesebuttons,
        pphkButtons
      } = this;
      const dataSource = await this.orderList4Shipment(token);
      const showProduct = !(window.getShopifyStore() === 'Asteroom');
      let products = [];

      if (showProduct) {// products = await this.products(token);
        // setTimeout(async() => {
        //     products = await this.products(token);
        //     // this.set('model', products)
        //     // this.currentModel.setProducts('model.products', products)
        //     // this.context.products = products
        // }, 1);
      }

      let selectedColumns = columns;
      let selectedButtons = buttons;

      if (window.isChinesePage()) {
        selectedColumns = chineseColumns;
        selectedButtons = chinesebuttons;
      }

      if (window.getShopifyStore() === 'PpHk') {
        selectedButtons = pphkButtons;
      }

      return {
        options: {
          data: dataSource,
          order: [[3, 'desc']],
          buttons: selectedButtons,
          // select: { style: 'multi', selector: 'td:not(:first-child, .operations)', },
          columns: selectedColumns
        },
        showProduct: showProduct,
        products: products,
        datetimeOptions: {
          format: 'YYYY-MM-DD',
          viewMode: 'days',
          timezone: window.isChinesePage() ? 'Asia/Taipei' : 'America/Los_Angeles'
        },
        storeTitle: window.getStoreTitle(),
        mDialog: {
          title: 'Edit the Tracking Number',
          id: 'trackModal'
        },
        mDialogProductInventory: {
          title: '修改庫存',
          id: 'inventoryAdjustQuantity'
        },
        token
      };
    },

    actions: {
      async downloadPackingList(dt) {
        const token = this.session.data.authenticated.token;
        let selectedSNS = dt.rows({
          selected: true
        }).data().toArray().map(data => data.shopifySN);

        try {
          this.send('startSpinner');
          const response = await this.order.downloadPickingList(token, selectedSNS);

          if (response.ok) {
            this.download(response.data);
            this.send('orderList4Shipment', dt);
            this.toast.success('Completed');
          } else {
            throw response;
          }
        } catch (error) {
          if (error.code === 401) {
            await this.session.invalidate();
            this.transitionTo('/');
          } else if (error.code === 403) {
            this.transitionTo('/');
          } else {
            this.toast.error(error.message);
            this.transitionTo('/');
          }
        } finally {
          this.send('stopSpinner');
        }
      },

      async mailAndExport2Sap(dt) {
        const token = this.session.data.authenticated.token;
        let selectedSNS = dt.rows({
          selected: true
        }).data().toArray().map(data => data.shopifySN);

        try {
          this.send('startSpinner');
          const response = await this.order.mailAndExport2Sap(token, selectedSNS);

          if (response.ok) {
            this.send('orderList4Shipment', dt);
            this.toast.success('Completed');
          } else {
            throw response;
          }
        } catch (error) {
          if (error.code === 401) {
            await this.session.invalidate();
            this.transitionTo('/');
          } else if (error.code === 403) {
            this.transitionTo('/');
          } else {
            this.toast.error(error.message);
          }
        } finally {
          this.send('stopSpinner');
        }
      },

      async deleteOrder(dt, data) {
        const token = this.session.data.authenticated.token;
        const SN = data.shopifySN;

        try {
          this.send('startSpinner');
          const response = await this.order.deleteOrder(token, SN);

          if (response.ok) {
            this.send('orderList4Shipment', dt);
            this.toast.success('Completed');
          } else {
            throw response;
          }
        } catch (error) {
          if (error.code === 401) {
            await this.session.invalidate();
            this.transitionTo('/');
          } else if (error.code === 403) {
            this.transitionTo('/');
          } else {
            this.toast.error(error.message);
          }
        } finally {
          this.send('stopSpinner');
        }
      },

      async editOrder(dt, data, trackingNumber) {
        const token = this.session.data.authenticated.token;

        if (_Data.default.isEmptyString(trackingNumber)) {
          this.toast.error(`Tracking Number is empty`);
          return false;
        }

        try {
          this.send('startSpinner');
          const response = await this.order.editOrder(token, {
            shopifySN: data.shopifySN,
            trackingNumber: trackingNumber
          });

          if (response.ok) {
            this.send('orderList4Shipment', dt);
            this.toast.success('Completed');
          } else {
            throw response;
          }
        } catch (error) {
          if (error.code === 401) {
            await this.session.invalidate();
            this.transitionTo('/');
          } else if (error.code === 403) {
            this.transitionTo('/');
          } else {
            this.toast.error(error.message);
          }
        } finally {
          this.send('stopSpinner');
          $(`#${this.controller.model.mDialog.id}`).modal('hide');
        }
      },

      async orderList4Shipment(dt) {
        const token = this.session.data.authenticated.token;
        const dataSource = await this.orderList4Shipment(token);
        dt.clear().rows.add(dataSource).draw();
        dt.rows().deselect();
        dt.columns.adjust();
        $('table thead .details-control').css('width', '15px');
      },

      async products() {
        const token = this.session.data.authenticated.token;
        const dataSource = await this.products(token);
      },

      async adjustInventoryQuantity(inventoryItemId, addAmount, variant) {
        const token = this.session.data.authenticated.token;
        let body = {
          locationId: "",
          inventoryItemId: inventoryItemId.toString(),
          availableAdjustment: addAmount
        };

        try {
          this.send('startSpinner');
          const response = await this.order.adjustInventory(token, body);

          if (response.ok) {
            // document.getElementById(inventoryItemId.toString()).innerHTML=response.data;
            this.send('stopSpinner');
            $(`#${this.controller.model.mDialogProductInventory.id}`).modal('hide');
            this.toast.success("修改成功為: " + response.data + "(資料不會即時更新)"); // let newProducts = JSON.parse(JSON.stringify(this.modelFor(this.routeName).products));
            // newProducts.forEach( p => {
            //     p.variants.forEach(v => {
            //         if(v.inventoryItemId === inventoryItemId){
            //             v.inventoryQuantity = response.data;
            //         }
            //     });
            // });
            // Ember.set(this.modelFor(this.routeName), 'products', newProducts);
            // this.modelFor(this.routeName).products = [];
            // Ember.set('model', 'showProduct', true)
          } else {
            throw response;
          }
        } catch (error) {
          if (error.code === 401) {
            await this.session.invalidate();
            this.transitionTo('/');
          } else if (error.code === 403) {
            this.transitionTo('/');
          } else {
            this.toast.error(error.message);
          }
        }
      }

    },
    buttons: [{
      key: 'downloadPackingList',
      text: 'Download Packing List',
      className: 'btn-primary'
    }, {
      key: 'mailAndExport2Sap',
      text: 'Email and Export to Sap',
      className: 'btn-primary'
    }],
    chinesebuttons: [{
      key: 'downloadPackingList',
      text: '下載文件',
      className: 'btn-primary'
    }, {
      key: 'mailAndExport2Sap',
      text: '出貨完成(寄信並匯入SAP)',
      className: 'btn-primary'
    }],
    pphkButtons: [{
      key: 'downloadPackingList',
      text: '下載文件',
      className: 'btn-primary'
    }, {
      key: 'mailAndExport2Sap',
      text: '匯入SAP/出貨(寄信)',
      className: 'btn-primary sap-or-mail'
    }],
    columns: [{
      data: null,
      orderable: false,
      className: 'details-control',
      render: () => '<i class="fa fa-plus" aria-hidden="true"></i>'
    }, {
      data: null,
      orderable: false,
      checkboxes: {
        selectRow: true,
        selectAll: true,
        selectAllRender: '<input type="checkbox" class="dt-checkboxes">'
      }
    }, {
      data: 'shopifySN',
      title: 'Shopify SN',
      visible: false
    }, {
      data: 'idDisplay',
      title: 'ID NO'
    }, {
      data: 'dueDate',
      title: 'Due Date',
      render: (data, type, row, meta) => {
        return moment(data).tz('America/Los_Angeles').format('YYYY-MM-DD HH:mm:ss');
      }
    }, {
      data: 'email',
      title: 'Email'
    }, {
      data: 'shippedAddress',
      title: 'Shipping Address'
    }, // { data: 'sapId', title: 'Sap Id', defaultContent: '-'},
    {
      data: 'trackingCompany',
      title: 'Tracking Company',
      defaultContent: 'Fedex',
      className: 'text-center'
    }, // { 
    //     data: 'isShipped', 
    //     title: 'Ship<br/>Status', 
    //     defaultContent: '-', 
    //     className: 'text-center',
    //     render: (data) => {
    //         if(data) {
    //             return '<i class="fa fa-check-circle fa-lg text-success" aria-hidden="true"></i>';
    //         } else {
    //             return '<i class="fa fa-times-circle fa-lg text-danger" aria-hidden="true"></i>';
    //         }
    //     },
    // },
    {
      data: 'isDownloaded',
      title: 'Download<br>Status',
      defaultContent: '-',
      className: 'text-center',
      render: data => {
        if (data) {
          return `<i class="fa fa-check-circle fa-lg" aria-hidden="true"></i>`;
        } else {
          return `<i class="fa fa-info-circle fa-lg" aria-hidden="true"></i>`;
        }
      }
    }, // { 
    //     data: 'shipMailStatus', 
    //     title: 'Mail<br/>Status', 
    //     defaultContent: '-', 
    //     className: 'text-center',
    //     render: (data) => {
    //         if(data === 'Failed') {
    //             return `<span class="badge badge-danger">${data}</span>`;
    //         } else {
    //             return data;
    //         }
    //     } 
    // },
    {
      data: null,
      title: 'Operations',
      defaultContent: '-',
      orderable: false,
      className: 'text-center operations',
      render: (data, type, row, meta) => {
        let disabled = row.trackingNumber !== null;
        const trash = `<button id="delete-order" title="Delete" class="btn btn-sm btn-info rounded-circle"><i class="fa fa-trash" aria-hidden="true"></i></button>`;
        const edit = `<button id="edit-number" ${disabled ? 'disabled' : ''} title="Edit tracking number" class="btn btn-sm btn-info rounded-circle"><i class="fa fa-edit fa-1x" aria-hidden="true"></i></button>`;
        return `${edit} ${trash}`;
      }
    }, {
      data: 'trackingNumber',
      title: 'Note',
      orderable: false,
      render: data => {
        if (data) return '-';else return 'Must fill in the tracking number';
      }
    }],
    chineseColumns: [{
      data: null,
      orderable: false,
      className: 'details-control',
      render: () => '<i class="fa fa-plus" aria-hidden="true"></i>'
    }, {
      data: null,
      orderable: false,
      checkboxes: {
        selectRow: true,
        selectAll: false,
        selectAllRender: '<input type="checkbox" class="dt-checkboxes">'
      }
    }, {
      data: 'shopifySN',
      title: 'Shopify SN',
      visible: false
    }, {
      data: 'idDisplay',
      title: '訂單號碼',
      render: (data, type, row, meta) => {
        const info = row.note ? `<div data-tooltip='${row.note}' class="fa-sticky-note-wrapper"><i class="fa fa-sticky-note fa-lg" aria-hidden="true"></i></div>` : '';
        return `<div style='display:flex;column-gap:10px;'>${data}${info}</div>`;
      }
    }, {
      data: 'sapId',
      title: 'sapId'
    }, {
      data: 'dueDate',
      title: '日期',
      render: (data, type, row, meta) => {
        return moment(data).tz('Asia/Taipei').format('YYYY-MM-DD HH:mm:ss');
      }
    }, {
      data: 'email',
      title: '信箱'
    }, {
      data: 'shippedAddress',
      title: '運送地址'
    }, {
      data: 'trackingNumber',
      title: 'trackingNumber',
      orderable: false,
      render: data => {
        if (data) return data;else return '';
      }
    }, // { data: 'sapId', title: 'Sap Id', defaultContent: '-'},
    // { 
    //     data: 'trackingCompany', 
    //     title: '物流公司', 
    //     defaultContent: 'Fedex', 
    //     className: 'text-center' 
    // },
    // { 
    //     data: 'isShipped', 
    //     title: 'Ship<br/>Status', 
    //     defaultContent: '-', 
    //     className: 'text-center',
    //     render: (data) => {
    //         if(data) {
    //             return '<i class="fa fa-check-circle fa-lg text-success" aria-hidden="true"></i>';
    //         } else {
    //             return '<i class="fa fa-times-circle fa-lg text-danger" aria-hidden="true"></i>';
    //         }
    //     },
    // },
    {
      data: 'isDownloaded',
      title: '是否下載',
      defaultContent: '-',
      className: 'text-center',
      render: data => {
        if (data) {
          return `<i class="fa fa-check-circle fa-lg" aria-hidden="true"></i>`;
        } else {
          return `<i class="fa fa-info-circle fa-lg" aria-hidden="true"></i>`;
        }
      }
    }, // { 
    //     data: 'shipMailStatus', 
    //     title: 'Mail<br/>Status', 
    //     defaultContent: '-', 
    //     className: 'text-center',
    //     render: (data) => {
    //         if(data === 'Failed') {
    //             return `<span class="badge badge-danger">${data}</span>`;
    //         } else {
    //             return data;
    //         }
    //     } 
    // },
    {
      data: null,
      title: '操作',
      defaultContent: '-',
      orderable: false,
      className: 'text-center operations',
      render: (data, type, row, meta) => {
        // let disabled = (row.trackingNumber !== null);
        let disabled = false;
        const trash = `<button id="delete-order" title="Delete" class="btn btn-sm btn-info rounded-circle"><i class="fa fa-trash" aria-hidden="true"></i></button>`;
        const edit = `<button id="edit-number" ${disabled ? 'disabled' : ''} title="Edit tracking number" class="btn btn-sm btn-info rounded-circle"><i class="fa fa-edit fa-1x" aria-hidden="true"></i></button>`;
        return `${edit} ${trash}`;
      }
    }] // detailButtons: [
    //     {
    //         key: 'edit',
    //         autoClose: true,
    //         text: '<i class="fa fa-edit fa-1x" aria-hidden="true"></i>',
    //         className: 'btn btn-primary rounded-circle',
    //         titleAttr: 'Edit the Tracking number',
    //     }
    // ],

  });

  _exports.default = _default;
});