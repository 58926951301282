define("client-cma/src/order/return/route", ["exports", "client-cma/utils/Data"], function (_exports, _Data) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    order: Ember.inject.service(),
    toast: Ember.inject.service(),

    async orderList4Return(token, startOfDate, endOfDate) {
      let dataSource = [];

      try {
        const response = await this.order.orderList4Return(token, startOfDate, endOfDate);

        if (response.ok) {
          dataSource = response.data;
        } else {
          throw response;
        }

        return dataSource;
      } catch (error) {
        if (error.code === 401) {
          await this.session.invalidate();
          this.transitionTo('/');
        } else if (error.code === 403) {
          this.transitionTo('/');
        } else {
          this.toast.error(error.message);
        }
      }
    },

    async orderList4ReturnByStatus(token, data) {
      let dataSource = [];

      try {
        const response = await this.order.orderList4ReturnByStatus(token, data);

        if (response.ok) {
          dataSource = response.data;
        } else {
          throw response;
        }

        return dataSource;
      } catch (error) {
        if (error.code === 401) {
          await this.session.invalidate();
          this.transitionTo('/');
        } else if (error.code === 403) {
          this.transitionTo('/');
        } else {
          this.toast.error(error.message);
        }
      }
    },

    download(data) {
      let elm = document.createElement('a');
      elm.href = URL.createObjectURL(data.blob);
      elm.setAttribute('download', data.fileName);
      elm.click();
    },

    async model() {
      const token = this.session.data.authenticated.token;
      const {
        buttons,
        columns,
        chineseColumns,
        chinesebuttons
      } = this;
      const dataSource = await this.orderList4ReturnByStatus(token, null);
      let selectedColumns = columns;
      let selectedButtons = buttons;

      if (window.isChinesePage()) {
        selectedColumns = chineseColumns;
        selectedButtons = chinesebuttons;
      }

      return {
        options: {
          data: dataSource,
          buttons: selectedButtons,
          columns: selectedColumns,
          select: {
            selector: 'tr:not(.non-select) td:not(:first-child, .send-email, .credit-note)'
          }
        },
        datetimeOptions: {
          format: 'YYYY-MM',
          viewMode: 'months',
          timezone: window.isChinesePage() ? 'Asia/Taipei' : 'America/Los_Angeles'
        },
        storeTitle: window.getStoreTitle()
      };
    },

    setupController(controller, model) {
      controller.set('isChecked', false);
      controller.set('model', model);
    },

    actions: {
      async confirmReturnedOrders(dt) {
        const token = this.session.data.authenticated.token;
        let selectedSNS = dt.rows('.selected').data().toArray().map(data => data.shopifySN);

        try {
          this.send('startSpinner');
          const response = await this.order.confirmReturnedOrders(token, selectedSNS);

          if (response.ok) {
            // this.controller.send('filterData', dt);
            if (dt) {
              this.controller.send('filterData', dt);
            } else {
              this.controller.send('selectTheStatus', null);
            }

            this.toast.success('Confirm the Returned Orders Succcessfully');
          } else {
            throw response;
          }
        } catch (error) {
          if (error.code === 401) {
            await this.session.invalidate();
            this.transitionTo('/');
          } else if (error.code === 403) {
            this.transitionTo('/');
          } else {
            this.toast.error(error.message);
          }
        } finally {
          this.send('stopSpinner');
        }
      },

      async downloadPackingList(dt) {
        const token = this.session.data.authenticated.token;
        let selectedSNS = dt.rows('.selected').data().toArray().map(data => data.shopifySN);

        try {
          this.send('startSpinner');
          const response = await this.order.downloadPickingList(token, selectedSNS);

          if (response.ok) {
            this.download(response.data);

            if (dt) {
              this.controller.send('filterData', dt);
            } else {
              this.controller.send('selectTheStatus', null);
            }

            this.toast.success('Completed');
          } else {
            throw response;
          }
        } catch (error) {
          if (error.code === 401) {
            await this.session.invalidate();
            this.transitionTo('/');
          } else if (error.code === 403) {
            this.transitionTo('/');
          } else {
            this.toast.error(error.message);
          }
        } finally {
          this.send('stopSpinner');
        }
      },

      async LetterOfReturn(data, dt) {
        const token = this.session.data.authenticated.token;

        try {
          this.send('startSpinner');
          const response = await this.order.sendEmail(token, data);

          if (response.ok) {
            if (dt) {
              this.controller.send('filterData', dt);
            } else {
              this.controller.send('selectTheStatus', null);
            } // // this.controller.send('filterData', dt);
            // this.controller.send('selectTheStatus', null);


            this.toast.success('Send the Letter of Return Order Succcessfully');
          } else {
            throw response;
          }
        } catch (error) {
          if (error.code === 401) {
            await this.session.invalidate();
            this.transitionTo('/');
          } else if (error.code === 403) {
            alert("請至Shopify後台操作");
          } else {
            this.toast.error(error.message);
          }
        } finally {
          this.send('stopSpinner');
        }
      },

      async proceedCreditNote(SN, dt) {
        const token = this.session.data.authenticated.token;

        try {
          this.send('startSpinner');
          const response = await this.order.proceedCreditNote(token, SN);

          if (response.ok) {
            if (dt) {
              this.controller.send('filterData', dt);
            } else {
              this.controller.send('selectTheStatus', null);
            }

            this.toast.success('Complete');
          } else {
            throw response;
          }
        } catch (error) {
          if (error.code === 401) {
            await this.session.invalidate();
            this.transitionTo('/');
          } else if (error.code === 403) {
            this.transitionTo('/');
          } else {
            this.toast.error(error.message);
          }
        } finally {
          this.send('stopSpinner');
        }
      },

      async orderList4Return(data, dt) {
        const token = this.session.data.authenticated.token;
        const isChinesePage = window.isChinesePage();

        var startDateAndEndDate = _Data.default.getStartDateAndEndDate(isChinesePage, data.date);

        let start = startDateAndEndDate.startDate;
        let end = startDateAndEndDate.endDate; // if(window.isChinesePage()){
        //     start = Data.startOfTaipeiTimeZone(data.mode, data.date);
        //     end = Data.endOfTaipeiTimeZone(data.mode, data.date);
        // }

        const dataSource = await this.orderList4Return(token, start, end);
        dt.clear().rows.add(dataSource).draw();
        dt.rows().deselect();
        dt.columns.adjust();
        $('table thead .details-control').css('width', '15px');
      },

      async orderList4ReturnByStatus(status, dt) {
        const token = this.session.data.authenticated.token;
        const data = _Data.default.isEmptyString(status) ? {} : {
          cancelStatus: status
        };

        try {
          const response = await this.order.orderList4ReturnByStatus(token, data);

          if (response.ok) {
            const dataSource = response.data;
            dt.clear().rows.add(dataSource).draw();
            dt.rows().deselect();
            dt.columns.adjust();
            $('table thead .details-control').css('width', '15px');
          } else {
            throw response;
          }
        } catch (error) {
          if (error.code === 401) {
            await this.session.invalidate();
            this.transitionTo('/');
          } else if (error.code === 403) {
            this.transitionTo('/');
          } else {
            this.toast.error(error.message);
          }
        }
      }

    },
    buttons: [{
      key: 'downloadPackingList',
      text: 'Download Packing List',
      className: 'btn-primary'
    }, {
      key: 'confirmReturnedOrders',
      text: 'Complete Return Orders',
      className: 'btn-primary'
    }],
    chinesebuttons: [{
      key: 'downloadPackingList',
      text: '下載文件',
      className: 'btn-primary'
    }, {
      key: 'confirmReturnedOrders',
      text: '已收到退貨',
      className: 'btn-primary'
    }],
    columns: [{
      data: null,
      orderable: false,
      className: 'details-control',
      render: () => '<i class="fa fa-plus" aria-hidden="true"></i>'
    }, {
      data: null,
      orderable: false,
      render: (data, type, row, meta) => {
        let disabled = data.cancelStatus === 'Returned';
        return `<input type="checkbox" class="dt-checkboxes" ${disabled ? 'disabled' : ''}>`;
      },
      createdCell: (td, cellData, rowData, row, col) => {
        if (cellData.cancelStatus === 'Returned') {
          $(td).parent().addClass('non-select');
          $(td).children().attr('disabled', true);
        }
      },
      checkboxes: {
        selectRow: true,
        selectCallback: (nodes, selected) => {},
        selectAllCallback: (nodes, selected, indeterminate) => {},
        selectAll: false // selectAllRender: '<input type="checkbox" class="dt-checkboxes">'

      }
    }, {
      data: 'shopifySN',
      title: 'Shopify SN',
      visible: false
    }, {
      data: 'idDisplay',
      title: 'ID NO'
    }, {
      data: 'dueDate',
      title: 'Due Date',
      render: (data, type, row, meta) => {
        return moment(data).tz('America/Los_Angeles').format('YYYY-MM-DD HH:mm:ss');
      }
    }, {
      data: 'sapId',
      title: 'Sap Id',
      defaultContent: '-'
    }, {
      data: 'cancelStatus',
      title: 'Cancel<br/>Status',
      defaultContent: '-',
      className: 'text-center',
      render: data => {
        return `<span class="badge">${data ? data : '-'}</span>`;
      }
    }, {
      data: 'isReturnInSap',
      title: 'Credit Note',
      defaultContent: '-',
      className: 'credit-note text-center',
      render: (data, type, row, meta) => {
        console.log(row.cancelStatus);
        const action = `</span><button id="credit-note" class="btn btn-sm btn-link"><i class="fa fa-file" aria-hidden="true"></i> proceed</button>`;
        const check = '<i class="fa fa-check-circle fa-lg" aria-hidden="true"></i>';
        const info = '<i class="fa fa-info-circle fa-lg" aria-hidden="true"></i>';
        return `${row.cancelStatus === 'Returned' ? data ? check : action : info}`;
      }
    }, {
      data: 'email',
      title: 'Email'
    }, {
      data: 'shippedAddress',
      title: 'Shipping Address'
    } // { 
    //     data: 'trackingCompany', 
    //     title: 'Tracking Company', 
    //     defaultContent: 'Fedex', 
    //     className: 'text-center' 
    // },
    // { 
    //     data: 'isShipped', 
    //     title: 'Ship<br/>Status', 
    //     defaultContent: '-', 
    //     className: 'text-center',
    //     render: (data) => {
    //         if(data) {
    //             return '<i class="fa fa-check-circle fa-lg" aria-hidden="true"></i>';
    //         } else {
    //             return '<i class="fa fa-times-circle fa-lg" aria-hidden="true"></i>';
    //         }
    //     },
    // },
    // { 
    //     data: 'isDownloaded', 
    //     title: 'Download<br/>Status', 
    //     defaultContent: '-', 
    //     className: 'text-center', 
    //     render: (data) => {
    //         if(data) {
    //             return `<span class="badge badge-success">${data}</span>`;
    //         } else {
    //             return `<span class="badge badge-danger">${data}</span>`;
    //         }
    //     },
    // },
    // { 
    //     data: 'returnMailStatus', 
    //     title: 'Mail<br/>Status', 
    //     defaultContent: '-', 
    //     className: 'send-email text-center',
    //     render: (data) => {
    //         return `<span class="badge">${data ? data : 'Failure'}</span><button id="send-email" class="btn btn-sm btn-link"><i class="fa fa-envelope" aria-hidden="true"></i> send mail</button>`;
    //     } 
    // },
    // { data: 'note', title: 'Note', defaultContent: '-',className: 'text-center' },
    ],
    chineseColumns: [{
      data: null,
      orderable: false,
      className: 'details-control',
      render: () => '<i class="fa fa-plus" aria-hidden="true"></i>'
    }, {
      data: null,
      orderable: false,
      render: (data, type, row, meta) => {
        let disabled = data.cancelStatus === 'Returned';
        return `<input type="checkbox" class="dt-checkboxes" ${disabled ? 'disabled' : ''}>`;
      },
      createdCell: (td, cellData, rowData, row, col) => {
        if (cellData.cancelStatus === 'Returned') {
          $(td).parent().addClass('non-select');
          $(td).children().attr('disabled', true);
        }
      },
      checkboxes: {
        selectRow: true,
        selectCallback: (nodes, selected) => {},
        selectAllCallback: (nodes, selected, indeterminate) => {},
        selectAll: false // selectAllRender: '<input type="checkbox" class="dt-checkboxes">'

      }
    }, {
      data: 'shopifySN',
      title: 'Shopify SN',
      visible: false
    }, {
      data: 'idDisplay',
      title: '訂單號碼'
    }, {
      data: 'dueDate',
      title: 'Due Date',
      render: (data, type, row, meta) => {
        return moment(data).tz('Asia/Taipei').format('YYYY-MM-DD HH:mm:ss');
      }
    }, {
      data: 'email',
      title: '信箱'
    }, {
      data: 'shippedAddress',
      title: '運送地址'
    }, {
      data: 'sapId',
      title: 'Sap Id',
      defaultContent: '-'
    }, {
      data: 'trackingNumber',
      title: 'trackingNumber',
      orderable: false,
      render: data => {
        if (data) return data;else return '';
      }
    }, // { 
    //     data: 'trackingCompany', 
    //     title: 'Tracking Company', 
    //     defaultContent: 'Fedex', 
    //     className: 'text-center' 
    // },
    // { 
    //     data: 'isShipped', 
    //     title: 'Ship<br/>Status', 
    //     defaultContent: '-', 
    //     className: 'text-center',
    //     render: (data) => {
    //         if(data) {
    //             return '<i class="fa fa-check-circle fa-lg" aria-hidden="true"></i>';
    //         } else {
    //             return '<i class="fa fa-times-circle fa-lg" aria-hidden="true"></i>';
    //         }
    //     },
    // },
    // { 
    //     data: 'isDownloaded', 
    //     title: 'Download<br/>Status', 
    //     defaultContent: '-', 
    //     className: 'text-center', 
    //     render: (data) => {
    //         if(data) {
    //             return `<span class="badge badge-success">${data}</span>`;
    //         } else {
    //             return `<span class="badge badge-danger">${data}</span>`;
    //         }
    //     },
    // },
    {
      data: 'returnMailStatus',
      title: '是否寄信成功',
      defaultContent: '-',
      className: 'send-email text-center',
      render: data => {
        return `<span class="badge">${data ? data : 'Failure'}</span><button id="send-email" class="btn btn-sm btn-link"><i class="fa fa-envelope" aria-hidden="true"></i> send mail</button>`;
      }
    }, {
      data: 'cancelStatus',
      title: '退貨狀態',
      defaultContent: '-',
      className: 'text-center',
      render: data => {
        return `<span class="badge">${data ? data : '-'}</span>`;
      }
    }, {
      data: 'isReturnInSap',
      title: 'SAP是否銷帳',
      defaultContent: '-',
      className: 'credit-note text-center',
      render: (data, type, row, meta) => {
        console.log(row.cancelStatus);
        const action = `</span><button id="credit-note" class="btn btn-sm btn-link"><i class="fa fa-file" aria-hidden="true"></i> proceed</button>`;
        const check = '<i class="fa fa-check-circle fa-lg" aria-hidden="true"></i>';
        const info = '<i class="fa fa-info-circle fa-lg" aria-hidden="true"></i>';
        return `${row.cancelStatus === 'Returned' ? data ? check : action : info}`;
      }
    } // { data: 'note', title: 'Note', defaultContent: '-',className: 'text-center' },
    ]
  });

  _exports.default = _default;
});