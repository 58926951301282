define("client-cma/services/auth", ["exports", "client-cma/config/environment", "fetch"], function (_exports, _environment, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    async login(email, password) {
      try {
        const response = await (0, _fetch.default)(`${_environment.default.APP.rootURL}auth/login`, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: email,
            password: password
          })
        });

        if (response.ok) {
          const data = await response.json();
          const authentication = {
            id: data.id,
            token: data.token,
            email: data.email,
            writable: data.readWrite,
            canUseAsteroom: data.canUseAsteroom,
            canUsePpHk: data.canUsePpHk,
            canUsePpInc: data.canUsePpInc,
            canUsePpTw: data.canUsePpTw,
            canUseCounselling: data.canUseCounselling,
            canImportSapRpOrAp: data.canImportSapRpOrAp,
            canImportAsteroomDynamicCustomer: data.canImportAsteroomDynamicCustomer,
            canImportAsteroomFixedCustomer: data.canImportAsteroomFixedCustomer
          };
          return {
            ok: response.ok,
            data: authentication,
            code: response.status,
            message: response.statusText
          };
        }

        throw {
          ok: response.ok,
          data: [],
          code: response.status,
          message: response.statusText
        };
      } catch (error) {
        return error;
      }
    },

    async isAuthenticated(token) {
      try {
        const response = await (0, _fetch.default)(`${_environment.default.APP.rootURL}auth/check`, {
          method: 'post',
          headers: {
            'Auth-Token': token
          }
        });

        if (response.ok) {
          const data = await response.json();
          const authentication = {
            id: data.accountLoginInfo.id,
            token: data.accountLoginInfo.token,
            email: data.accountLoginInfo.email,
            writable: data.readWrite
          };
          return {
            ok: response.ok,
            data: authentication,
            code: response.status,
            message: response.statusText
          };
        }

        throw {
          ok: response.ok,
          data: [],
          code: response.status,
          message: response.statusText
        };
      } catch (error) {
        return error;
      }
    }

  });

  _exports.default = _default;
});