define("client-cma/components/dashmenu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    tagName: 'button',
    classNameBindings: ['isDisabled:disabled:'],
    classNames: ['menu'],
    isDisabled: false,

    click() {
      let {
        route,
        router,
        isDisabled
      } = this;
      if (!isDisabled) router.transitionTo(route);
      return false;
    }

  });

  _exports.default = _default;
});