define("client-cma/authenticators/custom", ["exports", "ember-simple-auth/authenticators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    auth: Ember.inject.service(),

    restore(data) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (Ember.isEmpty(data.token)) reject();else resolve(data);
      });
    },

    async authenticate({
      email,
      password
    }) {
      const response = await this.auth.login(email, password);

      if (response.ok) {
        return response.data;
      } else {
        throw response;
      }
    },

    invalidate(data) {
      return new Ember.RSVP.Promise(function (resolve) {
        resolve();
      });
    }

  });

  _exports.default = _default;
});