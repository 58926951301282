define("client-cma/src/pphk/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    auth: Ember.inject.service(),
    session: Ember.inject.service(),
    toast: Ember.inject.service(),

    async model() {
      try {
        const token = this.session.data.authenticated.token;
        const response = await this.auth.isAuthenticated(token);

        if (response.ok) {
          if (this.session.data.authenticated.canUsePpHk === false) {
            alert("你沒有權限，請洽管理員");
            this.transitionTo('/');
          }

          const account = response.data.email;
          const options = [{
            content: '待出貨',
            route: '/pphk/order/process'
          }, {
            content: '已出貨',
            route: '/pphk/order/complete'
          }, {
            content: '所有訂單',
            route: '/pphk/order/list'
          }, {
            content: '退貨訂單',
            route: '/pphk/order/return'
          } // {
          //     content: 'Calibrate Address',
          //     route: '/pphk/order/address'
          // }
          ];
          return {
            options: options,
            account: account
          };
        } else {
          throw response;
        }
      } catch (error) {
        this.toast.error('AUTHENTICATE_FAIL');
        await this.session.invalidate();
        this.transitionTo('login');
      }
    },

    actions: {
      homePage() {
        this.transitionTo('/');
      }

    }
  });

  _exports.default = _default;
});