define("client-cma/src/order/stripe/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      pickTheDate(date, mode) {
        const dt = $('.dataTable').DataTable(); // const filter = this.filter;

        this.set('filter', '');
        this.send('getStripeOrderList', {
          date: date,
          mode: mode,
          filter: ''
        }, dt);
      },

      filterData(dt) {
        const filter = this.filter;
        const oTable = Ember.typeOf(dt) !== 'undefined' ? dt : $('.dataTable').DataTable();
        const date = moment($("#datetimepicker").data().date);
        const mode = $('#datetimepicker').data("DateTimePicker").options().viewMode.slice(0, -1);
        this.send('getStripeOrderList', {
          date: date,
          mode: mode,
          filter: filter
        }, oTable);
      },

      packageListByOrder() {}

    }
  });

  _exports.default = _default;
});