define("client-cma/src/order/return/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    isChecked: true,
    actions: {
      btnClick(dt, config) {
        this.send(config.key, dt, config);
      },

      pickTheDate(date, mode) {
        const dt = $('.dataTable').DataTable();
        this.send('orderList4Return', {
          date: date,
          mode: mode
        }, dt);
      },

      filterData(dt) {
        if ($("#datetimepicker").data()) {
          const date = moment($("#datetimepicker").data().date);
          const mode = $('#datetimepicker').data("DateTimePicker").options().viewMode.slice(0, -1);
          this.send('orderList4Return', {
            date: date,
            mode: mode
          }, dt);
        } else {
          const dt = $('.dataTable').DataTable();
          this.send('orderList4ReturnByStatus', status, dt);
        }
      },

      mail(dt, data) {
        this.send('LetterOfReturn', {
          shopifySN: data.shopifySN,
          mailSubject: 'ReturnLetter'
        }, dt);
      },

      proceed(dt, data) {
        this.send('proceedCreditNote', data.shopifySN, dt);
      },

      change(isByDate) {
        this.set('isChecked', isByDate);

        if (isByDate) {
          this.send('pickTheDate', new moment(), 'month');
        } else {
          this.send('selectTheStatus');
        }
      },

      selectTheStatus(status) {
        const dt = $('.dataTable').DataTable();
        this.send('orderList4ReturnByStatus', status, dt);
      }

    }
  });

  _exports.default = _default;
});