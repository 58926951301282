define("client-cma/src/order/rptosap/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    requestPaymentType: '',
    toast: Ember.inject.service(),
    actions: {
      toSap() {
        const excelData = document.querySelector('#exceldata');

        if (excelData == null || excelData.files[0] == null) {
          this.toast.error("No file selected");
          return;
        }

        if (this.get('requestPaymentType') === 'Error') {
          this.toast.error("Wrong File Format");
          return;
        }

        this.send('importRequestPaymentToSap', excelData.files[0]);
      },

      toSapAP() {
        const excelData = document.querySelector('#exceldata');

        if (excelData == null || excelData.files[0] == null) {
          this.toast.error("No file selected");
          return;
        }

        if (this.get('requestPaymentType') === 'Error') {
          this.toast.error("Wrong File Format");
          return;
        }

        this.send('importRequestPaymentToSapAP', excelData.files[0]);
      },

      asteroomToSap() {
        const excelData = document.querySelector('#exceldata');

        if (excelData == null || excelData.files[0] == null) {
          this.toast.error("No file selected");
          return;
        }

        const postingDate = document.querySelector('#postingDate').value;

        if (postingDate === '') {
          this.toast.error("請選擇過帳日");
          return;
        }

        this.send('importAsteroomOrderToSap', excelData.files[0], postingDate);
      },

      asteroomToSapAr() {
        const excelData = document.querySelector('#exceldata');

        if (excelData == null || excelData.files[0] == null) {
          this.toast.error("No file selected");
          return;
        }

        const postingDate = document.querySelector('#postingDateAr').value;

        if (postingDate === '') {
          this.toast.error("請選擇過帳日");
          return;
        }

        this.send('importAsteroomOrderToSapAr', excelData.files[0], postingDate);
      },

      asteroomToSapRWC008() {
        const excelData = document.querySelector('#exceldata');

        if (excelData == null || excelData.files[0] == null) {
          this.toast.error("No file selected");
          return;
        }

        this.send('importAsteroomOrderToSapRWC008', excelData.files[0]);
      },

      inputOnChange(e) {
        this.send('checkRequestPaymentType', e.target.files[0]);
      }

    }
  });

  _exports.default = _default;
});