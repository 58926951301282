define("client-cma/components/modal-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['modal', 'fade'],
    attributeBindings: ['tabindex', 'role', 'aria-hidden="true'],
    tabindex: '-1',
    role: 'dialog',
    'aria-hidden': true,
    btnConfirmTitie: 'Confirm',
    btnCancelTitle: 'Cancel',

    didInsertElement() {
      $('.modal').on('show.bs.modal', e => {
        $('.wrapper').css('position', 'absolute');
      }).on('shown.bs.modal', e => {}).on('hide.bs.modal', e => {
        $('.wrapper').css('position', 'fixed');
      }).on('hidden.bs.modal', e => {});
    }

  });

  _exports.default = _default;
});