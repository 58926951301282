define("client-cma/components/datetimepicker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement() {
      const {
        options,
        dpChange
      } = this;
      console.log(options);
      const dp = $('#datetimepicker').datetimepicker({
        defaultDate: new moment().tz('Asia/Taipei'),
        format: options.format || 'YYYY-MM',
        viewMode: options.viewMode || 'years',
        useCurrent: true,
        icons: {
          time: "fa fa-clock-o",
          date: "fa fa-calendar",
          up: "fa fa-arrow-up",
          down: "fa fa-arrow-down",
          previous: "fa fa-chevron-left",
          next: "fa fa-chevron-right",
          today: "fa fa-clock-o",
          clear: "fa fa-trash-o"
        }
      });
      dp.on('dp.change', e => {
        if (dpChange && Ember.typeOf(dpChange) === 'function') {
          dpChange(e.date, this.options.viewMode.slice(0, -1));
        }
      });
    }

  });

  _exports.default = _default;
});