define("client-cma/helpers/customSkuDisplay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.customSkuDisplay = customSkuDisplay;
  _exports.default = void 0;

  function customSkuDisplay(params) {
    if (params[0] === 'no') {
      return 'No';
    }

    return `${params[0]} - ${params[1]}`;
  }

  ;

  var _default = Ember.Helper.helper(customSkuDisplay);

  _exports.default = _default;
});