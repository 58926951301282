define("client-cma/templates/components/modal-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H036kzDs",
    "block": "{\"symbols\":[\"&default\",\"@comfirm\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"modal-dialog modal-dialog-centered modal-lg\"],[10,\"role\",\"document\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-content\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n            \"],[7,\"h3\",true],[10,\"class\",\"modal-title\"],[8],[1,[22,\"modalTitle\"],false],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n            \"],[14,1],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n            \"],[7,\"button\",false],[12,\"class\",\"btn btn-primary\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],[23,2,[]]]],[8],[1,[22,\"btnConfirmTitie\"],false],[9],[0,\"\\n            \"],[7,\"button\",true],[10,\"class\",\"btn btn-outline-secondary\"],[10,\"data-dismiss\",\"modal\"],[10,\"type\",\"button\"],[8],[1,[22,\"btnCancelTitle\"],false],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client-cma/templates/components/modal-dialog.hbs"
    }
  });

  _exports.default = _default;
});