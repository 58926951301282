define("client-cma/helpers/concatHelper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.concatHelper = concatHelper;
  _exports.default = void 0;

  function concatHelper(params) {
    return params[0] + "-" + params[1];
  }

  ;

  var _default = Ember.Helper.helper(concatHelper);

  _exports.default = _default;
});