define("client-cma/router", ["exports", "client-cma/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('login');
    this.route('asteroom');
    this.route('ppinc');
    this.route('pphk');
    this.route('pptw');
    this.route('counselling');
    this.route('order', {
      path: '/:shopifyStore/order'
    }, function () {
      this.route('list');
      this.route('process');
      this.route('complete');
      this.route('return');
      this.route('address');
      this.route('rptosap');
      this.route('amazonfbatosap');
      this.route('freehw');
      this.route('invoicetosap');
      this.route('stripe');
      this.route('tosaparinvoice');
    }); // this.route('asteroom', function(){
    //   this.route('order', function() {
    //     this.route('list');
    //     this.route('process');
    //     this.route('complete');
    //     this.route('return');
    //     this.route('address');
    //   });
    // }, );
    // this.route('order', function() {
    //   this.route('list');
    //   this.route('process');
    //   this.route('complete');
    //   this.route('return');
    //   this.route('address');
    // });
  });
  var _default = Router;
  _exports.default = _default;
});