define("client-cma/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/2Rt7SjJ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"wrapper\"],[8],[0,\"\\n    \"],[1,[22,\"outlet\"],false],[0,\"\\n    \"],[15,\"spinner\",[]],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "client-cma/templates/application.hbs"
    }
  });

  _exports.default = _default;
});