define("client-cma/helpers/containHelper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.containHelper = containHelper;
  _exports.default = void 0;

  function containHelper(params) {
    if (!params[0]) return false;
    return params[0].indexOf(params[1]) !== -1;
  }

  ;

  var _default = Ember.Helper.helper(containHelper);

  _exports.default = _default;
});