define("client-cma/src/counselling/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uCwAoJav",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"dashborad-container\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"top\"],[8],[0,\"\\n        \\n        \"],[7,\"button\",false],[12,\"class\",\"btn btn-primary\"],[12,\"style\",\"border-radius: 50%;\"],[3,\"action\",[[23,0,[]],\"homePage\"]],[8],[0,\"\\n            \"],[7,\"i\",true],[10,\"class\",\"fas fa-home fa-1x\"],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"button\",false],[12,\"class\",\"btn btn-primary\"],[3,\"action\",[[23,0,[]],\"signOut\"]],[8],[0,\"\\n            \"],[7,\"i\",true],[10,\"class\",\"icon icon-sign-out-white\"],[8],[9],[0,\"\\n            \"],[7,\"span\",true],[8],[1,[24,[\"model\",\"account\"]],false],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"h1\",true],[8],[0,\"逍遙心理\"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"dashboard\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\",\"options\"]]],null,{\"statements\":[[0,\"            \"],[5,\"dashmenu\",[],[[\"@route\",\"@content\"],[[23,1,[\"route\"]],[23,1,[\"content\"]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client-cma/src/counselling/template.hbs"
    }
  });

  _exports.default = _default;
});