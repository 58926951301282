define("client-cma/src/order/tosaparinvoice/route", ["exports", "client-cma/utils/Data"], function (_exports, _Data) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    order: Ember.inject.service(),
    toast: Ember.inject.service(),

    setupController(controller, model) {
      console.log(this.session.data.authenticated); // this.session.data.authenticated.canImportSap
      // this.session.data.authenticated.canImportSapRpOrAp = true
      // this.session.data.authenticated.canImportAsteroomDynamicCustomer = true
      // this.session.data.authenticated.canImportAsteroomFixedCustomer = true

      const {
        canImportSapRpOrAp,
        canImportAsteroomDynamicCustomer,
        canImportAsteroomFixedCustomer
      } = this.session.data.authenticated;
      controller.set('canImportSapRpOrAp', canImportSapRpOrAp);
      controller.set('canImportAsteroomDynamicCustomer', canImportAsteroomDynamicCustomer);
      controller.set('canImportAsteroomFixedCustomer', canImportAsteroomFixedCustomer);
      controller.set('filter', '');
      controller.set('model', model);
    },

    async orderList4Shipped(token, queryParams) {
      let dataSource = [];

      try {
        const response = await this.order.orderList4Shipped(token, queryParams);

        if (response.ok) {
          dataSource = response.data;
        } else {
          throw response;
        }

        return dataSource;
      } catch (error) {
        if (error.code === 401) {
          await this.session.invalidate();
          this.transitionTo('/');
        } else if (error.code === 403) {
          this.transitionTo('/');
        } else {
          this.toast.error(error.message);
        }
      }
    },

    download(data) {
      let elm = document.createElement('a');
      elm.href = URL.createObjectURL(data.blob);
      elm.setAttribute('download', data.fileName);
      elm.click();
    },

    actions: {
      async setReturnOrders(dt) {
        const token = this.session.data.authenticated.token;
        let selectedSNS = dt.rows({
          selected: true
        }).data().toArray().map(data => data.shopifySN);

        try {
          this.send('startSpinner');
          const response = await this.order.setReturnOrders(token, selectedSNS);

          if (response.ok) {
            this.controller.send('filterData', dt);
            this.toast.success('Succcessful');
          } else {
            throw response;
          }
        } catch (error) {
          if (error.code === 401) {
            await this.session.invalidate();
            this.transitionTo('/');
          } else if (error.code === 403) {
            alert("沒有操作權限");
          } else {
            this.toast.error(error.message);
          }
        } finally {
          this.send('stopSpinner');
        }
      },

      async checkRequestPaymentType(file) {
        const token = this.session.data.authenticated.token;
        this.send('startSpinner');

        try {
          const response = await this.order.checkRequestPaymentType(token, file);

          if (response.ok) {
            this.controller.set('requestPaymentType', response.data);
          } else {
            throw response;
          }
        } catch (error) {
          this.toast.error(error.message);
        } finally {
          this.send('stopSpinner');
        }
      },

      async importRequestPaymentToSap(file) {
        const token = this.session.data.authenticated.token;
        this.send('startSpinner');

        try {
          const response = await this.order.importAsteroomRequestPaymentToSap(token, file);

          if (response.ok) {
            this.toast.success('Import To Sap Purchase order Succcessfully');
          } else {
            this.toast.error("error");
            throw response;
          }
        } catch (error) {
          this.toast.error(error.message);
        } finally {
          this.send('stopSpinner');
        }
      },

      async importRequestPaymentToSapAP(file) {
        const token = this.session.data.authenticated.token;
        this.send('startSpinner');

        try {
          const response = await this.order.importAsteroomRequestPaymentToSapAP(token, file);

          if (response.ok) {
            this.toast.success('Import To Sap AP Succcessfully');
          } else {
            this.toast.error("error");
            throw response;
          }
        } catch (error) {
          this.toast.error(error.message);
        } finally {
          this.send('stopSpinner');
        }
      },

      async importAsteroomOrderToSap(file, postingDate) {
        const token = this.session.data.authenticated.token;
        this.send('startSpinner');

        try {
          const response = await this.order.importAsteroomOrderToSapAP(token, file, postingDate);

          if (response.ok) {
            this.toast.success('Import To Sap AP Succcessfully');
          } else {
            this.toast.error("error");
            throw response;
          }
        } catch (error) {
          this.toast.error(error.message);
        } finally {
          this.send('stopSpinner');
        }
      },

      async importCounsellingToArInvoice(file) {
        const token = this.session.data.authenticated.token;
        this.send('startSpinner');

        try {
          const response = await this.order.importCounsellingToArInvoice(token, file);

          if (response.ok) {
            const datas = Object.entries(response.data);

            if (datas.length > 0) {
              //有錯誤
              this.toast.error("匯入失敗");
              this.controller.set('counsellingErrorDatails', datas);
            } else {
              this.toast.success('匯入成功');
              this.controller.set('counsellingErrorDatails', []);
            }
          } else {
            this.toast.error("error");
            throw response;
          }
        } catch (error) {
          this.toast.error(error.message);
        } finally {
          this.send('stopSpinner');
        }
      },

      async importAsteroomOrderToSapRWC008(file) {
        const token = this.session.data.authenticated.token;
        this.send('startSpinner');

        try {
          const response = await this.order.importAsteroomOrderToSapRWC008(token, file);

          if (response.ok) {
            this.toast.success('Import To Sap AP Succcessfully');
          } else {
            this.toast.error("error");
            throw response;
          }
        } catch (error) {
          this.toast.error(error.message);
        } finally {
          this.send('stopSpinner');
        }
      },

      async LetterOfThanks(data, dt) {
        const token = this.session.data.authenticated.token;

        try {
          this.send('startSpinner');
          const response = await this.order.sendEmail(token, data);

          if (response.ok) {
            this.controller.send('filterData', dt);
            this.toast.success('Send the Letter of Thanks Succcessfully');
          } else {
            throw response;
          }
        } catch (error) {
          if (error.code === 401) {
            await this.session.invalidate();
            this.transitionTo('/');
          } else if (error.code === 403) {
            alert("請至Shopify後台操作");
          } else {
            this.toast.error(error.message);
          }
        } finally {
          this.send('stopSpinner');
        }
      },

      async orderList4Shipped(data, dt) {
        const token = this.session.data.authenticated.token;
      },

      test() {
        return "hk";
      }

    }
  });

  _exports.default = _default;
});