define("client-cma/src/order/freehw/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    requestPaymentType: '',
    toast: Ember.inject.service(),
    datas: [{
      id: '001',
      skus: []
    }],
    sapSkus: [{
      "name": "EN-Asteroom 3D Tour Kit II",
      "sku": "S360TK02EN"
    }, {
      "name": "Asteroom fisheye lens",
      "sku": "RLENSIBK100"
    }, {
      "name": "asteroom 360 rotating platform - large size",
      "sku": "RPXWCRTW200"
    }, {
      "name": "Asteroom Tripod",
      "sku": "RTRDWFK0100"
    }, {
      "name": "Lens Clip only",
      "sku": "Misc"
    }],
    iphoneCases: [{
      "name": "no",
      "sku": "no"
    }, {
      "name": "Case-iPhone 7 Plus & iPhone 8 Plus",
      "sku": "SCSI78P1UN"
    }, {
      "name": "Case-iPhone X & iPhone XS",
      "sku": "SCSIXXS1UN"
    }, {
      "name": "Case-iPhone XR",
      "sku": "SCSIXR01UN"
    }, {
      "name": "Case-iPhone XS Max",
      "sku": "SCSIXSM1UN"
    }, {
      "name": "Case-iPhone 11",
      "sku": "SCSI1101UN"
    }, {
      "name": "Case-iPhone 11 Pro",
      "sku": "SCSI11P1UN"
    }, {
      "name": "Case-iPhone 11 Pro Max",
      "sku": "SCSI11M1UN"
    }, {
      "name": "Case-iPhone 12 Pro",
      "sku": "SCSI12P1UN"
    }, {
      "name": "Case-iPhone 12 Pro Max",
      "sku": "SCSI12PMUN"
    }, {
      "name": "Case-iPhone 12 mini",
      "sku": "SCSI12MNUN"
    }, {
      "name": "Case-iPhone 12",
      "sku": "SCSI1201UN"
    }, {
      "name": "Case-iPhone 13",
      "sku": "SCSI1301UN"
    }, {
      "name": "Case-iPhone 13 Pro",
      "sku": "SCSI13P1UN"
    }, {
      "name": "Case-iPhone 13 Pro Max",
      "sku": "SCSI13PMUN"
    }, {
      "name": "Case-iPhone 13 Mini",
      "sku": "SCSI13MNUN"
    }, {
      "name": "Case-iPhone 7 & iPhone 8 & iPhone SE 2020",
      "sku": "SCSI7I81UN"
    }, {
      "name": "Case-iPhone 14",
      "sku": "SCSI1401UN"
    }, {
      "name": "Case-iPhone 14 Plus",
      "sku": "SCSI14PLUN"
    }, {
      "name": "Case-iPhone 14 Pro",
      "sku": "SCSI14PRUN"
    }, {
      "name": "Case-iPhone 14 Pro Max",
      "sku": "SCSI14PMUN"
    }, {
      "name": "Case-iPhone 15",
      "sku": "SCSI1501UN"
    }, {
      "name": "Case-iPhone 15 Plus",
      "sku": "SCSI15PLUN"
    }, {
      "name": "Case-iPhone 15 Pro",
      "sku": "SCSI15PRUN"
    }, {
      "name": "Case-iPhone 15 Pro Max",
      "sku": "SCSI15PMUN"
    }],
    samsungCases: [{
      "name": "no",
      "sku": "no"
    }, {
      "name": "Case-Samsung S8",
      "sku": "SCSSSS81UN"
    }, {
      "name": "Case-Samsung S8 Plus",
      "sku": "SCSSS8P1UN"
    }, {
      "name": "Case-Samsung S9",
      "sku": "SCSSSS91UN"
    }, {
      "name": "Case-Samsung S9 Plus",
      "sku": "SCSSS9P1UN"
    }, {
      "name": "Case-Samsung S10 Plus",
      "sku": "SCSSSSXP1UN"
    }, {
      "name": "Case-Samsung NOTE10",
      "sku": "SCSSSNTX1UN"
    }, {
      "name": "Case-Samsung NOTE10 Plus",
      "sku": "SCSSSNXP1UN"
    }, {
      "name": "Case-Samsung S20",
      "sku": "SCSSSS20UN"
    }, {
      "name": "Case-Samsung S20 Plus",
      "sku": "SCSSSS20PUN"
    }, {
      "name": "Case-Samsung S21",
      "sku": "SCSSSS21UN"
    }, {
      "name": "Case-Samsung S21 Ultra",
      "sku": "SCSSS21UUN"
    }, {
      "name": "Case-Samsung S20 Ultra",
      "sku": "SCSSSS20UUN"
    }, {
      "name": "Case-Samsung Note20 Ultra",
      "sku": "SCSSSN2XUUN"
    }, {
      "name": "Case-Samsung S21 Plus",
      "sku": "SCSSSS21PUN"
    }, {
      "name": "Case-Samsung S22 Ultra",
      "sku": "SCSSS22UUN"
    }, {
      "name": "Case-Samsung S22",
      "sku": "SCSSSS22UN"
    }, {
      "name": "Case-Samsung S22 Plus",
      "sku": "SCSSS22PUN"
    }, {
      "name": "Case-Samsung S23",
      "sku": "SCSSSS23UN"
    }, {
      "name": "Case-Samsung S23 Plus",
      "sku": "SCSSS23PUN"
    }, {
      "name": "Case-Samsung S23 Ultra",
      "sku": "SCSSS23UUN"
    }],
    title: "",
    mailData: null,
    isAddressSplitFormat: false,
    selectedColor: null,
    actions: {
      insertOneToContainer() {
        const oldDatas = this.get('datas');
        this.set('datas', [...oldDatas, {
          skus: [],
          id: ('00' + (oldDatas.length + 1)).slice(-3)
        }]);
      },

      titleOnInput(e) {
        this.set('title', e.target.value);
      },

      removeOneFormContainer(id) {
        const oldDatas = this.get('datas');

        if (oldDatas.length === 1) {
          return alert('at least one item');
        }

        const newDatas = oldDatas.filter(d => d.id !== id);
        const newDatasWithCorrectId = [];
        newDatas.forEach((n, idx) => {
          const nWithNewId = { ...n,
            id: ('00' + (idx + 1)).slice(-3)
          };
          newDatasWithCorrectId.push(nWithNewId);
        });
        this.set('datas', newDatasWithCorrectId);
      },

      onNameInput(e) {
        const oldDatas = this.get('datas');
        const idx = Number(e.target.closest('.freehw-id').dataset.idx);
        const newDatas = oldDatas.map((oldData, idxInside) => {
          if (idxInside === idx) {
            return { ...oldData,
              name: e.target.value
            };
          }

          return oldData;
        });
        console.log(JSON.stringify(newDatas));
        this.set('datas', newDatas);
      },

      onAddressInput(e) {
        console.log(e);
        const oldDatas = this.get('datas');
        const idx = Number(e.target.closest('.freehw-id').dataset.idx);
        const newDatas = oldDatas.map((oldData, idxInside) => {
          if (idxInside === idx) {
            return { ...oldData,
              address: e.target.value
            };
          }

          return oldData;
        });
        console.log(JSON.stringify(newDatas));
        this.set('datas', newDatas);
      },

      onPhoneInput(e) {
        const oldDatas = this.get('datas');
        const idx = Number(e.target.closest('.freehw-id').dataset.idx);
        const newDatas = oldDatas.map((oldData, idxInside) => {
          if (idxInside === idx) {
            return { ...oldData,
              phone: e.target.value
            };
          }

          return oldData;
        });
        console.log(JSON.stringify(newDatas));
        this.set('datas', newDatas);
      },

      onSaumsungCaseChange(target) {
        const oldDatas = this.get('datas');
        const idx = Number(target.closest('.freehw-id').dataset.idx);
        const newDatas = oldDatas.map((oldData, idxInside) => {
          if (idxInside === idx) {
            if (target.value === 'no') {
              return { ...oldData,
                samsungCase: null
              };
            }

            return { ...oldData,
              samsungCase: target.value
            };
          }

          return oldData;
        });
        console.log(JSON.stringify(newDatas));
        this.set('datas', newDatas);
      },

      onIphoneCaseChange(target) {
        const oldDatas = this.get('datas');
        const idx = Number(target.closest('.freehw-id').dataset.idx);
        const newDatas = oldDatas.map((oldData, idxInside) => {
          if (idxInside === idx) {
            if (target.value === 'no') {
              return { ...oldData,
                iphoneCase: null
              };
            }

            return { ...oldData,
              iphoneCase: target.value
            };
          }

          return oldData;
        });
        console.log(JSON.stringify(newDatas));
        this.set('datas', newDatas);
      },

      onMailDataInput(e) {
        let val = e.target.value;
        const isSplitByTab = val.indexOf('Name\t') !== -1;
        const isSplitByLine = val.indexOf('Name\n') !== -1;
        const isAddressSplit = val.indexOf('City') !== -1 && val.indexOf('State') !== -1 && val.indexOf('Postal') !== -1;
        console.log("isAdressSplit=" + isAddressSplit);
        let arr = [];
        let newDatas = [];

        if (isAddressSplit) {
          if (isSplitByTab) {
            val = val.replaceAll('\n\t', '\t');
            val = val.replaceAll('\n', '\t');
            arr = val.split('\t');
            arr = arr.filter(a => a.indexOf('-') !== 0 && a !== '' && !['Name', 'Notes', 'Address-StreetLines', 'City', 'State', 'Postal Code', 'Phone #', 'Email', 'Reason'].includes(a));
          } else if (isSplitByLine) {
            val = val.replaceAll('\n\n', '\n');
            arr = val.split('\n');
            arr = arr.filter(a => a.indexOf('-') !== 0 && a !== '' && !['Name', 'Notes', 'Address-StreetLines', 'City', 'State', 'Postal Code', 'Phone #', 'Email', 'Reason'].includes(a));
          } else {
            this.set('datas', [{
              id: '001',
              skus: []
            }]);
            return;
          }
        } else {
          if (isSplitByTab) {
            val = val.replaceAll('\n\t', '\t');
            val = val.replaceAll('\n', '\t');
            arr = val.split('\t');
            arr = arr.filter(a => a.indexOf('-') !== 0 && a !== '' && !['Name', 'Notes', 'Address', 'Phone #', 'Email', 'Reason'].includes(a));
          } else if (isSplitByLine) {
            val = val.replaceAll('\n\n', '\n');
            arr = val.split('\n');
            arr = arr.filter(a => a.indexOf('-') !== 0 && a !== '' && !['Name', 'Notes', 'Address', 'Phone #', 'Email', 'Reason'].includes(a));
          } else {
            this.set('datas', [{
              id: '001',
              skus: []
            }]);
            return;
          }
        }

        if (isAddressSplit) {
          let idx = 1;

          for (let i = 0; i < arr.length - 7; i += 8) {
            console.log(arr[i + 1]);
            let data = {
              name: arr[i],
              address: {
                street: arr[i + 1],
                city: arr[i + 2],
                state: arr[i + 3],
                zip: arr[i + 4]
              },
              phone: arr[i + 5],
              email: arr[i + 6],
              reason: arr[i + 7],
              id: ('00' + idx).slice(-3),
              skus: []
            };
            newDatas.push(data);
            idx++;
          }

          this.set('datas', newDatas);
        } else {
          let idx = 1;

          for (let i = 0; i < arr.length - 4; i += 5) {
            console.log(arr[i + 1]);
            let data = {
              name: arr[i],
              address: {
                full: arr[i + 1]
              },
              phone: arr[i + 2],
              email: arr[i + 3],
              reason: arr[i + 4],
              id: ('00' + idx).slice(-3),
              skus: []
            };
            newDatas.push(data);
            idx++;
          }

          this.set('datas', newDatas);
        } // console.log(newDatas)


        this.set('isAddressSplitFormat', isAddressSplit);
        this.set('mailData', e.target.value);
      },

      onCheckboxChecked(e) {
        const oldDatas = this.get('datas');
        const idx = Number(e.target.closest('.freehw-id').dataset.idx);
        const checked = e.target.checked;
        const sku = e.target.id.split('-')[1];
        const newDatas = oldDatas.map((oldData, idxInside) => {
          if (idxInside === idx) {
            if (checked) {
              return { ...oldData,
                skus: [...oldData.skus, sku]
              };
            } else {
              return { ...oldData,
                skus: oldData.skus.filter(v => v !== sku)
              };
            }
          }

          return oldData;
        });
        console.log(JSON.stringify(newDatas));
        this.set('datas', newDatas); // const oldDatas = this.get('datas')
        // const idx = Number(e.target.dataset.freehwidx)
        // // document.querySelector(`.freehw-content[]`).querySelectorAll('input[type]:checked')
        // const checkes = [...e.target.closest('.freehw-content').querySelectorAll('input[type]:checked')].map(ch => ch.name)
        // const newDatas = oldDatas.map((oldData, idxInside) => {
        //     if(idxInside === idx){
        //         const obj = {}
        //         checkes.forEach(c => {
        //             obj[c] = true
        //         })
        //         return {...oldData, items: obj}
        //     }
        //     return oldData
        // })
        // console.log(JSON.stringify(newDatas))
        // this.set('datas', newDatas)
      },

      toSap() {
        const self = this;
        const requestDatas = [];
        const title = this.get('title');

        if (!title || title.trim() === '') {
          return alert('請輸入客戶參考prefix');
        } else {}

        const datas = this.get('datas');
        let somethingBlank = [];
        datas.forEach(d => {
          const idCombine = title + d.id;

          if (!d.name || !d.address || !d.phone || d.name.trim() === '' || d.address.full.trim() === '' || d.phone.trim() === '') {
            somethingBlank.push(idCombine);
          } else if (!d.iphoneCase && !d.samsungCase && d.skus.length === 0) {
            somethingBlank.push(idCombine);
          } else {
            requestDatas.push({ ...d,
              id: idCombine
            });
          }
        });

        if (somethingBlank.length > 0) {
          return alert(`資料有缺: ${somethingBlank.join(', ')}`);
        }

        console.log(JSON.stringify(requestDatas));
        this.send('toFreeHardwareSap', {
          items: requestDatas
        }); // this.set('datas', [{id: '001'}])
      },

      toProcessingOrders() {
        const title = this.get('title');

        if (!title || title.trim() === '') {
          return alert('請輸入客戶參考prefix');
        } else {}

        const datas = this.get('datas');
        const dataWithDescription = datas.map(d => {
          const skuDescription = {};
          d.skus.forEach(s => {
            skuDescription[s] = this.get('sapSkus').find(c => c.sku === s).name;
          });

          if (d.iphoneCase && d.iphoneCase !== '' && d.iphoneCase !== 'no') {
            skuDescription[d.iphoneCase] = this.get('iphoneCases').find(c => c.sku === d.iphoneCase).name;
          }

          if (d.samsungCase && d.samsungCase !== '' && d.samsungCase !== 'no') {
            skuDescription[d.samsungCase] = this.get('samsungCases').find(c => c.sku === d.samsungCase).name;
          }

          return { ...d,
            skuDescription,
            id: title + d.id
          };
        });
        console.log(dataWithDescription);
        this.send('transferToProcessingOrders', {
          items: dataWithDescription
        });
      }

    }
  });

  _exports.default = _default;
});