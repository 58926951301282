define("client-cma/src/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MD008Ynw",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"login-container\"],[8],[0,\"\\n    \"],[7,\"form\",true],[10,\"class\",\"login\"],[8],[0,\"\\n        \"],[7,\"h2\",true],[10,\"class\",\"title\"],[8],[0,\"CMA\"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n            \"],[7,\"label\",true],[10,\"for\",\"email\"],[8],[0,\"Email\"],[9],[0,\"\\n            \"],[1,[28,\"input\",null,[[\"id\",\"type\",\"class\",\"value\"],[\"email\",\"text\",\"form-control\",[24,[\"email\"]]]]],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n            \"],[7,\"label\",true],[10,\"for\",\"password\"],[8],[0,\"Password\"],[9],[0,\"\\n            \"],[1,[28,\"input\",null,[[\"id\",\"type\",\"class\",\"value\",\"autocomplete\"],[\"password\",\"password\",\"form-control\",[24,[\"password\"]],\"on\"]]],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"btn-login\"],[8],[0,\"\\n            \"],[7,\"button\",false],[12,\"class\",\"btn btn-primary btn-block\"],[3,\"action\",[[23,0,[]],\"authenticate\"]],[8],[0,\"Login\"],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client-cma/src/login/template.hbs"
    }
  });

  _exports.default = _default;
});