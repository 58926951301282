define("client-cma/src/login/route", ["exports", "client-cma/utils/Data", "client-cma/config/environment"], function (_exports, _Data, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    toast: Ember.inject.service(),

    beforeModal() {
      if (this.session.isAuthenticated) {
        this.transitionTo('/');
      }
    },

    actions: {
      async authenticate() {
        try {
          this.send('startSpinner');
          let account = {
            email: this.controller.email,
            password: this.controller.password
          };

          const message = _Data.default.validateObject(account);

          if (_Data.default.isEmptyArray(message)) {
            await this.session.authenticate(_environment.default.APP.AUTHENTICATOR, account);
          } else {
            throw new Error(message);
          }

          if (this.session.isAuthenticated) this.transitionTo('/');
        } catch (error) {
          this.toast.error(error.message);
        } finally {
          this.send('stopSpinner');
        }

        return false;
      }

    }
  });

  _exports.default = _default;
});