define("client-cma/utils/Data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getStartDateAndEndDate = _exports.endOfTaipeiTimeZone = _exports.startOfTaipeiTimeZone = _exports.endOf = _exports.startOf = _exports.validateObject = _exports.isEmptyString = _exports.isEmptyArray = _exports.isEmptyObject = void 0;

  const isEmptyObject = object => {
    return Ember.typeOf(object) === 'object' && Object.keys(object).length === 0;
  };

  _exports.isEmptyObject = isEmptyObject;

  const isEmptyArray = array => {
    return Ember.typeOf(array) === 'array' && Ember.isBlank(array);
  };

  _exports.isEmptyArray = isEmptyArray;

  const isEmptyString = string => {
    return Ember.isEmpty(string);
  };

  _exports.isEmptyString = isEmptyString;

  const validateObject = object => {
    let message = [];

    if (Ember.typeOf(object) === 'object') {
      for (const [key, value] of Object.entries(object)) {
        if (key === 'street2' || key === 'company' || key === 'ignoreSecondAddressCheck') {
          continue;
        }

        if (Ember.isBlank(value)) {
          message.push(`${key} is empty.`);
        }
      }
    }

    if (!isEmptyArray(message)) {
      return message.join('<br>');
    } else {
      return message;
    }
  };

  _exports.validateObject = validateObject;

  const startOf = (mode = 'day', day = new moment().tz('America/Los_Angeles')) => {
    if (day) return day.startOf(mode).add(7, 'hour').format('YYYY-MM-DD[T]HH:mm:ss[Z]');
  };

  _exports.startOf = startOf;

  const endOf = (mode = 'day', day = new moment().tz('America/Los_Angeles')) => {
    if (day) return day.endOf(mode).add(7, 'hour').format('YYYY-MM-DD[T]HH:mm:ss[Z]');
  };

  _exports.endOf = endOf;

  const startOfTaipeiTimeZone = (mode = 'day', day = new moment().tz('Asia/Taipei')) => {
    if (day) return day.startOf(mode).subtract(8, 'hour').format('YYYY-MM-DD[T]HH:mm:ss[Z]');
  };

  _exports.startOfTaipeiTimeZone = startOfTaipeiTimeZone;

  const endOfTaipeiTimeZone = (mode = 'day', day = new moment().tz('Asia/Taipei')) => {
    if (day) return day.endOf(mode).subtract(8, 'hour').format('YYYY-MM-DD[T]HH:mm:ss[Z]');
  };

  _exports.endOfTaipeiTimeZone = endOfTaipeiTimeZone;

  const getStartDateAndEndDate = (isTaipeiZone, day = new moment().startOf('month')) => {
    day = day.startOf('month');
    var isoTimeStartDate;
    isTaipeiZone = false;

    if (isTaipeiZone) {
      isoTimeStartDate = day.parseZone().tz("Asia/Taipei", true).toISOString(true);
    } else {
      isoTimeStartDate = day.parseZone().tz("America/Los_Angeles", true).toISOString(true);
    }

    console.log(moment(JSON.parse(JSON.stringify(isoTimeStartDate))));
    var isoEndDate = moment(JSON.parse(JSON.stringify(isoTimeStartDate))).endOf('month').toISOString(true);
    var utcStart = moment(isoTimeStartDate).utc().format();

    if (!isTaipeiZone) {
      utcStart = moment(isoTimeStartDate).utc().subtract('1', 'd').add('9', 'h').format();
    }

    var utcEnd = moment(isoEndDate).utc().format();
    console.log({
      utcStart,
      utcEnd
    });
    return {
      startDate: utcStart,
      endDate: utcEnd
    };
  };

  _exports.getStartDateAndEndDate = getStartDateAndEndDate;
});