define("client-cma/src/order/address/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      dblClick(data) {
        if (data.length === 0) return false;
        this.send('displayAddress', data);
      },

      btnConfirm() {
        const dt = $('.dataTable').DataTable();
        const {
          street,
          street2,
          company,
          city,
          zip,
          shopifySN,
          ignoreSecondAddressCheck
        } = this.address;
        const state = $('#state option:selected').val();
        const country = $('#country option:selected').val();
        this.send('calibrateAddress', dt, {
          shopifySN: shopifySN,
          street: street,
          street2: street2,
          company: company,
          city: city,
          state: state,
          country: country,
          zip: zip,
          ignoreSecondAddressCheck: ignoreSecondAddressCheck
        });
      }

    }
  });

  _exports.default = _default;
});