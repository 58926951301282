define("client-cma/src/order/address/route", ["exports", "client-cma/utils/Data"], function (_exports, _Data) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    order: Ember.inject.service(),
    toast: Ember.inject.service(),

    async addressList4Calibrated(token) {
      let dataSource = [];

      try {
        const response = await this.order.addressList4Calibrated(token);

        if (response.ok) {
          dataSource = response.data;
        } else {
          throw response;
        }

        return dataSource;
      } catch (error) {
        if (error.code === 401) {
          await this.session.invalidate();
          this.transitionTo('/');
        } else if (error.code === 403) {
          this.transitionTo('/');
        } else {
          this.toast.error(error.message);
        }
      }
    },

    modalShow(state) {
      $('#state option').removeAttr('selected').filter(`[value=${state}]`).attr('selected', true);
      $(`#${this.controller.model.mDialog.id}`).modal({
        backdrop: 'static'
      });
    },

    modalHide() {
      $(`#${this.controller.model.mDialog.id}`).modal('hide');
    },

    async model() {
      const {
        columns
      } = this;
      const token = this.session.data.authenticated.token;
      const dataSource = await this.addressList4Calibrated(token);
      return {
        options: {
          data: dataSource,
          columns: columns,
          order: [[0, 'asc']]
        },
        mDialog: {
          title: 'Edit',
          id: 'addressModal'
        },
        storeTitle: window.getStoreTitle()
      };
    },

    actions: {
      async displayAddress(data) {
        const token = this.session.data.authenticated.token;
        const shopifySN = data.firstObject.shopifySN;

        try {
          this.send('startSpinner');
          const response = await this.order.addressDetail(token, shopifySN);

          if (response.ok) {
            const address = response.data;
            this.controller.set('address', address);
            this.controller.set('display', data.firstObject.idDisplay);
            this.modalShow(address.state);
          } else {
            throw response;
          }
        } catch (error) {
          if (error.code === 401) {
            await this.session.invalidate();
            this.transitionTo('/');
          } else if (error.code === 403) {
            this.transitionTo('/');
          } else {
            this.toast.error(error.message);
          }
        } finally {
          this.send('stopSpinner');
        }
      },

      async calibrateAddress(dt, address) {
        const token = this.session.data.authenticated.token;

        const message = _Data.default.validateObject(address);

        try {
          if (_Data.default.isEmptyArray(message)) {
            this.modalHide();
            this.send('startSpinner');

            if (address.ignoreSecondAddressCheck == null) {
              address.ignoreSecondAddressCheck = false;
            }

            const response = await this.order.calibrateAddress(token, address);

            if (response.ok) {
              const dataSource = await this.addressList4Calibrated(token);
              dt.clear().rows.add(dataSource).draw();
              this.toast.success('Calibrate address successful');
            } else {
              throw response;
            }
          } else {
            throw new Error(message);
          }
        } catch (error) {
          if (error.code === 401) {
            await this.session.invalidate();
            this.transitionTo('/');
          } else if (error.code === 403) {
            this.transitionTo('/');
          } else {
            this.toast.error(error.message);
          }
        } finally {
          this.send('stopSpinner');
        }
      }

    },
    columns: [{
      data: 'shopifySN',
      title: 'Shopify SN',
      visible: false
    }, {
      data: 'idDisplay',
      title: 'ID NO'
    }, {
      data: 'dueDate',
      title: 'Due Date',
      render: (data, type, row, meta) => {
        return moment(data).tz('America/Los_Angeles').format('YYYY-MM-DD HH:mm:ss');
      }
    }, {
      data: 'email',
      title: 'Email'
    }, {
      data: 'shippedAddress',
      title: 'Shipping Address'
    }, // { data: 'sapId', title: 'Sap Id', defaultContent: '-'},
    {
      data: 'trackingCompany',
      title: 'Tracking Company',
      defaultContent: 'Fedex',
      className: 'text-center'
    }, // { 
    //     data: 'isShipped', 
    //     title: 'Ship<br/>Status', 
    //     defaultContent: '-', 
    //     className: 'text-center',
    //     render: (data) => {
    //         if(data) {
    //             return '<i class="fa fa-check-circle fa-lg text-success" aria-hidden="true"></i>';
    //         } else {
    //             return '<i class="fa fa-times-circle fa-lg text-danger" aria-hidden="true"></i>';
    //         }
    //     },
    // },
    // { 
    //     data: 'isDownloaded', 
    //     title: 'Download<br/>Status', 
    //     defaultContent: '-', 
    //     className: 'text-center', 
    //     render: (data) => {
    //         if(data) {
    //             return `<span class="badge badge-success">${data}</span>`;
    //         } else {
    //             return `<span class="badge badge-danger">${data}</span>`;
    //         }
    //     },
    // },
    // { 
    //     data: 'mailStatus', 
    //     title: 'Mail<br/>Status', 
    //     defaultContent: '-', 
    //     className: 'text-center',
    //     render: (data) => {
    //         if(data === 'Failed') {
    //             return `<span class="badge badge-danger">${data}</span>`;
    //         } else {
    //             return data;
    //         }
    //     } 
    // },
    // { 
    //     data: 'cancelStatus', 
    //     title: 'Cancel<br/>Status', 
    //     defaultContent: '-', 
    //     className: 'text-center', 
    //     render: (data) => {
    //         return `<span class="badge">${data ? data : '-'}</span>`;
    //     } 
    // },
    {
      data: 'note',
      title: 'Note',
      defaultContent: '-',
      className: 'text-center'
    }]
  });

  _exports.default = _default;
});