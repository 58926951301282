define("client-cma/src/index/route", ["exports", "client-cma/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    auth: Ember.inject.service(),
    session: Ember.inject.service(),
    toast: Ember.inject.service(),

    setupController(controller, model) {
      controller.set('filter', '');
      controller.set('model', model);

      if (_environment.default.environment !== 'production') {
        document.documentElement.dataset.isTest = 'true';
      }
    },

    async model() {
      try {
        const token = this.session.data.authenticated.token;
        const response = await this.auth.isAuthenticated(token);

        if (response.ok) {
          const account = response.data.email;
          const options = [{
            content: 'Asteroom',
            route: '/asteroom',
            shopifyStore: 'asteroom',
            isDisabled: true
          }, {
            content: 'PPInc',
            route: '/ppinc',
            shopifyStore: 'ppinc',
            isDisabled: true
          }, {
            content: '香港蒙恬',
            route: '/pphk',
            shopifyStore: 'pphk',
            isDisabled: true
          }, {
            content: '台灣蒙恬',
            route: '/pptw',
            shopifyStore: 'pptw',
            isDisabled: true
          }, {
            content: '逍遙心理',
            route: '/counselling',
            shopifyStore: 'counselling',
            isDisabled: true
          } // {
          //     content: 'Processing Orders',
          //     route: '/order/process'
          // },
          // {
          //     content: 'Shipped Orders',
          //     route: '/order/complete'
          // },
          // {
          //     content: 'All Orders',
          //     route: '/order/list'
          // },
          // {
          //     content: 'Return Orders',
          //     route: '/order/return'
          // },
          // {
          //     content: 'Calibrate Address',
          //     route: '/order/address'
          // }
          ];
          console.log(options);

          if (this.session.data.authenticated.canUseAsteroom) {
            options.find(i => i.shopifyStore === 'asteroom').isDisabled = false;
          }

          if (this.session.data.authenticated.canUsePpHk) {
            options.find(i => i.shopifyStore === 'pphk').isDisabled = false;
          }

          if (this.session.data.authenticated.canUsePpInc) {
            options.find(i => i.shopifyStore === 'ppinc').isDisabled = false;
          }

          if (this.session.data.authenticated.canUsePpTw) {
            options.find(i => i.shopifyStore === 'pptw').isDisabled = false;
          }

          if (this.session.data.authenticated.canUseCounselling) {
            options.find(i => i.shopifyStore === 'counselling').isDisabled = false;
          }

          return {
            options: options,
            account: account
          };
        } else {
          throw response;
        }
      } catch (error) {
        this.toast.error('AUTHENTICATE_FAIL');
        await this.session.invalidate();
        this.transitionTo('login');
      }
    }

  });

  _exports.default = _default;
});