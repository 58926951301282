define("client-cma/src/order/process/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      btnClick(dt, config) {
        this.send(config.key, dt, config);
      },

      cancel(dt, data) {
        this.send('deleteOrder', dt, data);
      },

      edit(dt, data) {
        this.set('dt', dt);
        this.set('data', data);
        this.set('trackingNumber', null);
        $(`#${this.model.mDialog.id}`).modal({
          backdrop: 'static'
        });
      },

      btnConfirm() {
        this.send('editOrder', this.dt, this.data, this.trackingNumber);
      },

      inventoryAdjustQuantityBtnConfirm() {
        let addAmount = this.targetInventoryQuantity - this.originInventoryQuantity;

        if (addAmount === 0) {
          return;
        }

        this.send('adjustInventoryQuantity', this.inventoryItemId, addAmount, this.variant);
      },

      inventoryAdjustQuantity(variant) {
        let inventoryQuantity = variant.inventoryQuantity;

        if (isNaN(inventoryQuantity)) {
          alert("請輸入數字");
          return;
        }

        this.set('variant', variant);
        this.set('sku', variant.sku);
        this.set('inventoryItemId', variant.inventoryItemId);
        this.set('originInventoryQuantity', inventoryQuantity);
        this.set('targetInventoryQuantity', inventoryQuantity);
        $(`#${this.model.mDialogProductInventory.id}`).modal({
          backdrop: 'static'
        });
      }

    }
  });

  _exports.default = _default;
});