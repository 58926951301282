define("client-cma/src/order/list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "43CuGmM5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h1\",true],[8],[1,[24,[\"model\",\"storeTitle\"]],false],[9],[0,\"\\n\"],[7,\"h1\",true],[8],[0,\"All Orders\"],[9],[0,\"\\n\"],[7,\"h4\",true],[8],[0,\"(High-risk orders will be highlighted in red)\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"card-tool\"],[8],[0,\"\\n    \"],[5,\"datetimepicker\",[],[[\"@options\",\"@dpChange\"],[[24,[\"model\",\"datetimeOptions\"]],[28,\"action\",[[23,0,[]],\"pickTheDate\"],null]]]],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"form-group mb-1\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"input-group search\"],[8],[0,\"\\n            \"],[1,[28,\"input\",null,[[\"type\",\"class\",\"value\"],[\"text\",\"form-control\",[24,[\"filter\"]]]]],false],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"input-group-append\"],[8],[0,\"\\n                \"],[7,\"button\",false],[12,\"class\",\"btn btn-primary\"],[3,\"action\",[[23,0,[]],\"filterData\"]],[8],[7,\"i\",true],[10,\"class\",\"fa fa-search\"],[10,\"aria-hidden\",\"true\"],[8],[9],[9],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[5,\"datatable\",[],[[\"@options\"],[[24,[\"model\",\"options\"]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client-cma/src/order/list/template.hbs"
    }
  });

  _exports.default = _default;
});