define("client-cma/src/order/amazonfbatosap/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    requestPaymentType: '',
    toast: Ember.inject.service(),
    actions: {
      toPenpowerIncSap() {
        const excelData = document.querySelector('#exceldata');

        if (excelData == null || excelData.files[0] == null) {
          this.toast.error("No file selected");
          return;
        }

        const yes = confirm('你確定嗎？');

        if (!yes) {
          return;
        }

        this.send('importToPenpowerIncSap', excelData.files[0]);
      },

      toAsteroomSap() {
        const excelData = document.querySelector('#exceldata');

        if (excelData == null || excelData.files[0] == null) {
          this.toast.error("No file selected");
          return;
        }

        const yes = confirm('你確定嗎？');

        if (!yes) {
          return;
        }

        this.send('importToAsteroomSap', excelData.files[0]);
      },

      inputOnChange(e) {
        this.send('checkRequestPaymentType', e.target.files[0]);
      }

    }
  });

  _exports.default = _default;
});