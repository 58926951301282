define("client-cma/src/order/list/route", ["exports", "client-cma/utils/Data"], function (_exports, _Data) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    order: Ember.inject.service(),
    toast: Ember.inject.service(),

    async orderList4All(token, queryParams) {
      let dataSource = [];

      try {
        const response = await this.order.orderList4All(token, queryParams);

        if (response.ok) {
          dataSource = response.data;
        } else {
          throw response;
        }

        return dataSource;
      } catch (error) {
        if (error.code === 401) {
          await this.session.invalidate();
          this.transitionTo('/');
        } else if (error.code === 403) {
          this.transitionTo('/');
        } else {
          this.toast.error(error.message);
        }
      }
    },

    async model() {
      const token = this.session.data.authenticated.token;
      const {
        columns,
        chineseColumns,
        twColumns
      } = this;
      let isChinesePage = window.isChinesePage();

      var startDateAndEndDate = _Data.default.getStartDateAndEndDate(isChinesePage);

      let queryParams = {
        startDate: startDateAndEndDate.startDate,
        endDate: startDateAndEndDate.endDate
      }; // if(isChinesePage){
      //     queryParams = {
      //         startDate: Data.startOfTaipeiTimeZone('month'),
      //         endDate: Data.endOfTaipeiTimeZone('month')
      //     }
      // }

      const dataSource = await this.orderList4All(token, queryParams);
      let selectedColumns = columns;

      if (isChinesePage) {
        selectedColumns = chineseColumns;
      }

      if (window.getShopifyStore() === 'PpTw') {
        selectedColumns = twColumns;
      }

      return {
        options: {
          data: dataSource,
          columns: selectedColumns,
          select: {
            style: 'single',
            selector: 'td:not(:first-child)'
          }
        },
        datetimeOptions: {
          format: 'YYYY-MM',
          viewMode: 'months',
          timezone: isChinesePage ? 'Asia/Taipei' : 'America/Los_Angeles'
        },
        storeTitle: window.getStoreTitle()
      };
    },

    setupController(controller, model) {
      controller.set('filter', '');
      controller.set('model', model);
    },

    actions: {
      async orderList4All(data, dt) {
        const token = this.session.data.authenticated.token;
        const isChinesePage = window.isChinesePage();

        var startDateAndEndDate = _Data.default.getStartDateAndEndDate(isChinesePage, data.date);

        let queryParams = {
          startDate: startDateAndEndDate.startDate,
          endDate: startDateAndEndDate.endDate
        };

        if (!_Data.default.isEmptyString(data.filter)) {
          queryParams = {};
          queryParams.keyword = data.filter;
        }

        const dataSource = await this.orderList4All(token, queryParams);
        dt.clear().rows.add(dataSource).draw();
        dt.rows().deselect();
        dt.columns.adjust();
        $('table thead .details-control').css('width', '15px');
      }

    },
    columns: [{
      data: null,
      orderable: false,
      className: 'details-control',
      render: () => '<i class="fa fa-plus" aria-hidden="true"></i>'
    }, // { 
    //     data: null,
    //     orderable: false,
    //     checkboxes: {
    //         selectRow : true,
    //         selectAll: true,
    //         selectAllRender: '<input type="checkbox" class="dt-checkboxes">'
    //     } 
    // },
    {
      data: 'shopifySN',
      title: 'Shopify SN',
      visible: false
    }, {
      data: 'idDisplay',
      title: 'ID NO'
    }, {
      data: 'dueDate',
      title: 'Due Date',
      render: (data, type, row, meta) => {
        return moment(data).tz('America/Los_Angeles').format('YYYY-MM-DD HH:mm:ss');
      }
    }, {
      data: 'email',
      title: 'Email'
    }, {
      data: 'shippedAddress',
      title: 'Shipping Address'
    }, {
      data: 'sapId',
      title: 'Sap Id',
      defaultContent: '-'
    }, {
      data: 'trackingCompany',
      title: 'Tracking Company',
      defaultContent: 'Fedex',
      className: 'text-center'
    }, // { 
    //     data: 'isShipped', 
    //     title: 'Ship<br/>Status', 
    //     defaultContent: '-', 
    //     className: 'text-center',
    //     render: (data) => {
    //         if(data) {
    //             return '<i class="fa fa-check-circle fa-lg" aria-hidden="true"></i>';
    //         } else {
    //             return '<i class="fa fa-times-circle fa-lg" aria-hidden="true"></i>';
    //         }
    //     },
    // },
    // { 
    //     data: 'isDownloaded', 
    //     title: 'Download<br/>Status', 
    //     defaultContent: '-', 
    //     className: 'text-center', 
    //     render: (data) => {
    //         if(data) {
    //             return `<span class="badge badge-success">${data}</span>`;
    //         } else {
    //             return `<span class="badge badge-danger">${data}</span>`;
    //         }
    //     },
    // },
    // { 
    //     data: 'shipMailStatus', 
    //     title: 'Mail<br/>Status', 
    //     defaultContent: '-', 
    //     className: 'text-center',
    //     render: (data) => {
    //         return `<span class="badge">${data}</span>`;
    //     } 
    // },
    {
      data: 'cancelStatus',
      title: 'Cancel<br/>Status',
      defaultContent: '-',
      className: 'text-center',
      render: data => {
        return `<span class="badge">${data ? data : '-'}</span>`;
      }
    } // { data: 'note', title: 'Note', defaultContent: '-',className: 'text-center' },
    ],
    chineseColumns: [{
      data: null,
      orderable: false,
      className: 'details-control',
      render: () => '<i class="fa fa-plus" aria-hidden="true"></i>'
    }, // { 
    //     data: null,
    //     orderable: false,
    //     checkboxes: {
    //         selectRow : true,
    //         selectAll: true,
    //         selectAllRender: '<input type="checkbox" class="dt-checkboxes">'
    //     } 
    // },
    {
      data: 'shopifySN',
      title: 'Shopify SN',
      visible: false
    }, {
      data: 'idDisplay',
      title: '訂單號碼'
    }, {
      data: 'dueDate',
      title: 'Due Date',
      render: (data, type, row, meta) => {
        return moment(data).tz('Asia/Taipei').format('YYYY-MM-DD HH:mm:ss');
      }
    }, {
      data: 'email',
      title: '信箱'
    }, {
      data: 'shippedAddress',
      title: '運送地址'
    }, {
      data: 'sapId',
      title: 'Sap Id',
      defaultContent: '-'
    }, {
      data: 'trackingNumber',
      title: 'trackingNumber',
      orderable: false,
      render: data => {
        if (data) return data;else return '';
      }
    }, // { 
    //     data: 'trackingCompany', 
    //     title: 'Tracking Company', 
    //     defaultContent: 'Fedex', 
    //     className: 'text-center' 
    // },
    // { 
    //     data: 'isShipped', 
    //     title: 'Ship<br/>Status', 
    //     defaultContent: '-', 
    //     className: 'text-center',
    //     render: (data) => {
    //         if(data) {
    //             return '<i class="fa fa-check-circle fa-lg" aria-hidden="true"></i>';
    //         } else {
    //             return '<i class="fa fa-times-circle fa-lg" aria-hidden="true"></i>';
    //         }
    //     },
    // },
    // { 
    //     data: 'isDownloaded', 
    //     title: 'Download<br/>Status', 
    //     defaultContent: '-', 
    //     className: 'text-center', 
    //     render: (data) => {
    //         if(data) {
    //             return `<span class="badge badge-success">${data}</span>`;
    //         } else {
    //             return `<span class="badge badge-danger">${data}</span>`;
    //         }
    //     },
    // },
    // { 
    //     data: 'shipMailStatus', 
    //     title: 'Mail<br/>Status', 
    //     defaultContent: '-', 
    //     className: 'text-center',
    //     render: (data) => {
    //         return `<span class="badge">${data}</span>`;
    //     } 
    // },
    {
      data: 'cancelStatus',
      title: '取消狀態',
      defaultContent: '-',
      className: 'text-center',
      render: data => {
        return `<span class="badge">${data ? data : '-'}</span>`;
      }
    } // { data: 'note', title: 'Note', defaultContent: '-',className: 'text-center' },
    ],
    twColumns: [{
      data: null,
      orderable: false,
      className: 'details-control',
      render: () => '<i class="fa fa-plus" aria-hidden="true"></i>'
    }, {
      data: 'idDisplay',
      title: '訂單號碼',
      render: (data, type, row, meta) => {
        return `${row.idDisplay}<br>${row.email}<br>${moment(row.dueDate).tz('Asia/Taipei').format('YYYY-MM-DD HH:mm:ss')}`;
      }
    }, {
      data: 'sapId',
      title: 'Sap Id',
      defaultContent: '-'
    }, {
      data: 'shippedAddress',
      title: '運送地址'
    }, {
      data: 'detail',
      orderable: false,
      title: '發票內容',
      render: (data, type, row, meta) => {
        const d = JSON.parse(data);
        let content = '';

        if (d.InvoiceType === 'e-invoice' || !d.InvoiceType) {
          content += `<div>載具類別：綠界電子發票載具</div>`;
        } else if (d.InvoiceType === 'carrier') {
          content += `<div>載具類別：手機條碼</div>`;
          content += `<div>手機條碼：${d.CarrierNum}</div>`;
        } else if (d.InvoiceType === 'donate') {
          content += `<div>載具類別：無載具(捐贈)</div>`;
          content += `<div>捐贈代碼：${d.DonateCode}</div>`;
        } else if (d.InvoiceType === 'company') {
          content += `<div>載具類別：無載具(公司行號)</div>`;
          if (d.CompanyName) content += `<div>公司名稱：${d.CompanyName}</div>`;
          if (d.CompanyUniformNumber) content += `<div>統一編號：${d.CompanyUniformNumber}</div>`;
        } // const d = JSON.parse(data)
        // if(d.Currency.toUpperCase() === 'TWD' && d.CarrierType === -1){
        //     d.CarrierType = 1
        // }
        // let content = ''
        // for(let i = 0 ; i < d.PurchaseItems.length ; i++){
        //     const purchaseItem = d.PurchaseItems[i]
        //     content += `<div class='stripe-sub-detail'>`
        //     content += `<div class='stripe-sub-detail-header'>購買商品</div>`
        //     content += `<div>SKU：${purchaseItem.SAPItemCode.toUpperCase()}</div>`
        //     content += `<div>商品名：${purchaseItem.ProductName}</div>`
        //     content += `<div>數量：${purchaseItem.Quantity}</div>`
        //     content += `<div>單價：${purchaseItem.UnitPrice} ${d.Currency.toUpperCase()}</div>`
        //     content += `<div>總價：${d.Amount} ${d.Currency.toUpperCase()}</div>`
        //     content += `</div>`
        // }
        // if(d.CarrierType !== -1){
        //     content += `<div class='stripe-sub-detail'>`
        //     content += `<div class='stripe-sub-detail-header'>發票</div>`
        //     if(d.CarrierType === 5){
        //         content += `<div>載具類別：無載具(捐贈)</div>`
        //         content += `<div>捐贈代碼：${d.DonateToUnitCode}</div>`
        //     }else if(d.CarrierType === 1){
        //         content += `<div>載具類別：綠界電子發票載具</div>`
        //     }else if(d.CarrierType === 2){
        //         content += `<div>載具類別：自然人憑證</div>`
        //         content += `<div>自然人憑證號碼：${d.NationalPersonNumber}</div>`
        //     }else if(d.CarrierType === 3){
        //         content += `<div>載具類別：手機條碼</div>`
        //         content += `<div>手機條碼：${d.MobileBarcodeCarrier}</div>`
        //     }else if(d.CarrierType === 0){
        //         content += `<div>載具類別：無載具(列印電子發票)</div>`
        //         if(d.CompanyName) content += `<div>姓名：${d.CompanyName}</div>`
        //         if(d.CompanyPhone) content += `<div>電話：${d.CompanyPhone}</div>`
        //         if(d.CompanyAddress) content += `<div>地址：${d.CompanyAddress}</div>`
        //     }else if(d.CarrierType === 4){
        //         content += `<div>載具類別：無載具(公司行號)</div>`
        //         if(d.CompanyName) content += `<div>公司名稱：${d.CompanyName}</div>`
        //         if(d.CompanyPhone) content += `<div>公司電話：${d.CompanyPhone}</div>`
        //         if(d.CompanyAddress) content += `<div>公司地址：${d.CompanyAddress}</div>`
        //         if(d.CompanyIdentifier) content += `<div>統一編號：${d.CompanyIdentifier}</div>`
        //     }
        //     if(d.Note){
        //         content += `<div>備註：${Note}</div>`
        //     }
        //     content += `</div>`
        // }


        let html = `<div class=''>${content}</div>`;
        return html;
      }
    }]
  });

  _exports.default = _default;
});