define("client-cma/src/order/amazonfbatosap/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jylKKP94",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"upload-container\"],[8],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"for\",\"exceldata\"],[8],[0,\"Choose a file\"],[9],[0,\"\\n    \"],[7,\"input\",true],[10,\"id\",\"exceldata\"],[10,\"name\",\"avatar\"],[10,\"accept\",\".csv\"],[10,\"type\",\"file\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n    \\n    \"],[7,\"button\",true],[10,\"class\",\"btn btn-primary\"],[10,\"style\",\"max-width: 250px\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"toPenpowerIncSap\"],null]],[8],[0,\"匯入PenPower Inc Sap\"],[9],[0,\" \\n    \"],[7,\"button\",true],[10,\"class\",\"btn btn-primary\"],[10,\"style\",\"max-width: 250px\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"toAsteroomSap\"],null]],[8],[0,\"匯入Asteroom Sap\"],[9],[0,\" \\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client-cma/src/order/amazonfbatosap/template.hbs"
    }
  });

  _exports.default = _default;
});