define("client-cma/src/order/freehw/route", ["exports", "client-cma/utils/Data"], function (_exports, _Data) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    order: Ember.inject.service(),
    toast: Ember.inject.service(),
    actions: {
      async toFreeHardwareSap(data) {
        const token = this.session.data.authenticated.token;

        try {
          this.send('startSpinner');
          const response = await this.order.freehardwareToSap(token, data);

          if (response.ok) {
            this.toast.success("success");
          } else {
            throw response;
          }
        } catch (error) {
          if (error.code === 401) {
            await this.session.invalidate();
            this.transitionTo('/');
          } else if (error.code === 403) {
            alert("沒有操作權限");
          } else {
            this.toast.error(error.message);
          }
        } finally {
          this.send('stopSpinner');
        }
      },

      async transferToProcessingOrders(data) {
        const token = this.session.data.authenticated.token;

        try {
          this.send('startSpinner');
          const response = await this.order.freehardwareToProcessingOrders(token, data);

          if (response.ok) {
            this.toast.success("success");
          } else {
            throw response;
          }
        } catch (error) {
          if (error.code === 401) {
            await this.session.invalidate();
            this.transitionTo('/');
          } else if (error.code === 403) {
            alert("沒有操作權限");
          } else {
            this.toast.error(error.message);
          }
        } finally {
          this.send('stopSpinner');
        }
      }

    }
  });

  _exports.default = _default;
});