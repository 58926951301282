define("client-cma/src/order/complete/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      btnClick(dt, config) {
        this.send(config.key, dt, config);
      },

      pickTheDate(date, mode) {
        //切換時間時不搜尋keyword
        const dt = $('.dataTable').DataTable(); // const filter = this.filter;

        this.set('filter', '');
        this.send('orderList4Shipped', {
          date: date,
          mode: mode,
          filter: ''
        }, dt);
      },

      filterData(dt) {
        const filter = this.filter;
        const oTable = Ember.typeOf(dt) !== 'undefined' ? dt : $('.dataTable').DataTable();
        const date = moment($("#datetimepicker").data().date);
        const mode = $('#datetimepicker').data("DateTimePicker").options().viewMode.slice(0, -1);
        this.send('orderList4Shipped', {
          date: date,
          mode: mode,
          filter: filter
        }, oTable);
      },

      mail(dt, data) {
        this.send('LetterOfThanks', {
          shopifySN: data.shopifySN,
          mailSubject: 'ThankYouLetter'
        }, dt);
      }

    }
  });

  _exports.default = _default;
});