define("client-cma/templates/components/datetimepicker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8FZh7bxu",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"form-group mb-1\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"input-group date\"],[10,\"id\",\"datetimepicker\"],[8],[0,\"\\n        \"],[7,\"input\",true],[10,\"class\",\"form-control\"],[10,\"type\",\"text\"],[8],[9],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"input-group-addon\"],[8],[0,\"\\n            \"],[7,\"span\",true],[10,\"class\",\"fas fa-calendar\"],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client-cma/templates/components/datetimepicker.hbs"
    }
  });

  _exports.default = _default;
});