define("client-cma/src/order/invoicetosap/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "15ckxjaH",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"upload-container\"],[8],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"for\",\"exceldata\"],[8],[0,\"Choose a file (.xlsx)\"],[9],[0,\"\\n    \"],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[7,\"input\",true],[10,\"id\",\"exceldata\"],[10,\"name\",\"avatar\"],[10,\"accept\",\"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet\"],[10,\"type\",\"file\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n    \\n    \"],[7,\"button\",true],[10,\"class\",\"btn btn-primary\"],[10,\"style\",\"max-width: 250px\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"invoiceToSap\"],null]],[8],[0,\"發票號碼回填AR發票\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client-cma/src/order/invoicetosap/template.hbs"
    }
  });

  _exports.default = _default;
});